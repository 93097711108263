import { ColDef } from "ag-grid-community";
import { ReferenceDataService } from "../../reference-data/reference-data.service";
import {
  ActionCellComponent,
  ActionCellProps,
} from "../cells/action-cell.component";
import { SpaOverride } from "src/app/shared/models/spaOverride";
import { ServicePointCellComponent } from "../cells/service-point-cell.component";
import { ServicePointHeaderComponent } from "../headers/service-point-header.component";
import { BasicEntityRefCellComponent } from "../cells/basic-entity-code-cell.component";
import { DateCellComponent } from "../cells/date-cell.component";
import { FlagCellComponent } from "../cells/flag-cell.component";
import { DateHeaderComponent } from "../headers/date-header.component";
import { EntityCodeHeaderComponent } from "../headers/entity-code-header.component";
import { FlagHeaderComponent } from "../headers/flag-header.component";
import {
  EntityRef,
  ServicePointIdReference,
} from "src/app/shared/models/referenceData";
import { FilterDateState, FilterDateStateFactory } from "src/app/shared/utilities/filter-date-state";
import { FilterListState, FilterListStateFactory } from "src/app/shared/utilities/filter-list-state";
import { NON_TERMINATING_NAMED_DATE } from "src/app/shared/models/namedDate";

export class SpahOverrideColumnDefinition {
  constructor(
    private filterDateStateFactory: FilterDateStateFactory<SpaOverride>,
    private filterListStateFactory: FilterListStateFactory<SpaOverride>,
    private refDataService: ReferenceDataService,
    private actions: ActionCellProps<SpaOverride>["actions"]
  ) {}

  public filters = {
    service_point_id: this.filterListStateFactory<ServicePointIdReference>(
      "service_point_id",
      (v) => v.service_point_id
    ),
    effective_date: this.filterDateStateFactory("effective_date"),
    termination_date: this.filterDateStateFactory("termination_date", [NON_TERMINATING_NAMED_DATE]),
    ldc_code: this.filterListStateFactory<EntityRef>(
      "ldc_code",
      (v) => v.entity_code
    ),
    eldc_code: this.filterListStateFactory<EntityRef>(
      "eldc_code",
      (v) => v.entity_code
    ),
    profile_class_code: this.filterListStateFactory<EntityRef>(
      "profile_class_code",
      (v) => v.entity_code
    ),
    retailer_code: this.filterListStateFactory<EntityRef>(
      "retailer_code",
      (v) => v.entity_code
    ),
    supply_class_code: this.filterListStateFactory<EntityRef>(
      "supply_class_code",
      (v) => v.entity_code
    ),
    meter_type_code: this.filterListStateFactory<EntityRef>(
      "meter_type_code",
      (v) => v.entity_code
    ),
    loss_class_code: this.filterListStateFactory<EntityRef>(
      "loss_class_code",
      (v) => v.entity_code
    ),
    location_code: this.filterListStateFactory<EntityRef>(
      "location_code",
      (v) => v.entity_code
    ),
    cpnode_code: this.filterListStateFactory<EntityRef>(
      "cpnode_code",
      (v) => v.entity_code
    ),
    service_point_source_code: this.filterListStateFactory<EntityRef>(
      "service_point_source_code",
      (v) => v.entity_code
    ),
    weather_sensitive_flag: this.filterListStateFactory<boolean>(
      "weather_sensitive_flag",
      (v) => `${v}`
    ),
    bmg_flag: this.filterListStateFactory<boolean>(
      "bmg_flag",
      (v) => `${v}`
    ),
    tie_flag: this.filterListStateFactory<boolean>(
      "tie_flag",
      (v) => `${v}`
    ),
    net_meter_rider_flag: this.filterListStateFactory<boolean>(
      "net_meter_rider_flag",
      (v) => `${v}`
    ),
    usage_ingestion_block_flag: this.filterListStateFactory<boolean>(
      "usage_ingestion_block_flag",
      (v) => `${v}`
    ),
    create_time: this.filterDateStateFactory("create_time"),
    last_update_time: this.filterDateStateFactory("last_update_time"),
  };

  public sharedDef: ColDef = {
    filter: true,
    sortable: true,
    editable: false,
    minWidth: 100,
    width: 150,
  };

  getColDefs = (): ColDef[] => [
    {
      ...this.sharedDef,
      width: 165,
      headerName: "Service Point",
      field: "service_point_id",
      cellRenderer: ServicePointCellComponent,
      cellRendererParams: {
        fetchServicePoints: this.refDataService.servicePoints.bind(
          this.refDataService
        ),
      },
      headerComponent: ServicePointHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["service_point_id"],
        fetchServicePoints: this.refDataService.servicePoints.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Effective Date",
      field: "effective_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["effective_date"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Termination Date",
      field: "termination_date",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
        namedDates: [NON_TERMINATING_NAMED_DATE],
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["termination_date"],
        namedDates: [NON_TERMINATING_NAMED_DATE],
      },
    },
    {
      ...this.sharedDef,
      headerName: "LDC",
      field: "ldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.lossDistributionCompanies.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["ldc_code"],
        fetchEntityRefs: this.refDataService.lossDistributionCompanies.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "ELDC",
      field: "eldc_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.lossDistributionCompanies.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["eldc_code"],
        fetchEntityRefs: this.refDataService.lossDistributionCompanies.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Profile Class",
      field: "profile_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.profileClasses.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["profile_class_code"],
        fetchEntityRefs: this.refDataService.profileClasses.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Retailer",
      field: "retailer_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.retailers.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["retailer_code"],
        fetchEntityRefs: this.refDataService.retailers.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Supply Class",
      field: "supply_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.supplyClasses.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["supply_class_code"],
        fetchEntityRefs: this.refDataService.supplyClasses.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Meter Type",
      field: "meter_type_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.meterTypes.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["meter_type_code"],
        fetchEntityRefs: this.refDataService.meterTypes.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Loss Class",
      field: "loss_class_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.lossClasses.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["loss_class_code"],
        fetchEntityRefs: this.refDataService.lossClasses.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Location",
      field: "location_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.locations.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["location_code"],
        fetchEntityRefs: this.refDataService.locations.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "CP Node",
      field: "cpnode_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.cpNodes.bind(this.refDataService),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["cpnode_code"],
        fetchEntityRefs: this.refDataService.cpNodes.bind(this.refDataService),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Service Point Source",
      field: "service_point_source_code",
      cellRenderer: BasicEntityRefCellComponent,
      cellRendererParams: {
        fetchEntityRefs: this.refDataService.servicePointSources.bind(
          this.refDataService
        ),
      },
      headerComponent: EntityCodeHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["service_point_source_code"],
        fetchEntityRefs: this.refDataService.servicePointSources.bind(
          this.refDataService
        ),
      },
    },
    {
      ...this.sharedDef,
      headerName: "Weather Sensitive",
      field: "weather_sensitive_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["weather_sensitive_flag"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "BMG",
      field: "bmg_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["bmg_flag"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Tie",
      field: "tie_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["tie_flag"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "New Meter Rider",
      field: "net_meter_rider_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["net_meter_rider_flag"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Usage Ingestion Block",
      field: "usage_ingestion_block_flag",
      cellRenderer: FlagCellComponent,
      headerComponent: FlagHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["usage_ingestion_block_flag"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Created On",
      field: "create_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
        editable: false,
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["create_time"],
      },
    },
    {
      ...this.sharedDef,
      headerName: "Last Updated On",
      field: "last_update_time",
      cellRenderer: DateCellComponent,
      cellRendererParams: {
        format: "YYY-MM-dd",
        editable: false,
      },
      headerComponent: DateHeaderComponent,
      headerComponentParams: {
        filterState: this.filters["last_update_time"],
      },
    },
    {
      ...this.sharedDef,
      width: 75,
      pinned: "right",
      resizable: false,
      suppressMovable: true,
      filter: false,
      sortable: false,
      cellRenderer: ActionCellComponent<SpaOverride>,
      cellRendererParams: {
        actions: this.actions,
      },
    },
  ];
}
