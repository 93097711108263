import { Component } from "@angular/core";
import { SpinnerComponent } from "src/app/public/spinner/spinner.component";


@Component({
    standalone: true,
    selector: '[app-select-menu-list-loading-item]',
    imports: [SpinnerComponent],
    template: `
    <div class="spinner-container">
       <app-spinner></app-spinner>
    </div>
    `,
    styles: `
    :host {
      position: relative;
      height: 6rem;
    }

    .spinner-container {
      position: absolute;
      left: 50%;
      top: 3rem;
    }
    `
})
export class SelectMenuListloadingComponent {
}