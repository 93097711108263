import { Component } from "@angular/core";
import { CellComponent } from "./cell.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatIcon } from "@angular/material/icon";

export type ActionCellProps<DATA> = {
  actions: {
    name: string,
    onAction: (d: DATA) => void
  }[]
}

@Component({
  standalone: true,
  imports: [MatMenuModule, MatIcon],
  styles: `
        :host {
            position: relative;
        }

        .menu-button {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: start;
          width: 100%;
          height: 100%;
        }

        .btn-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;
          width: 100%;

          .icon-btn {
            padding: 0;
            margin: 0;
            width: 2rem;
            height: 2rem;
            line-height: 1.5rem;
            border: none;
            background: none;
            border-radius: 50%;

            &:hover {
              background: gainsboro;
            }

            &:disabled {
              opacity: 0.5;
              background: none;
            }

            mat-icon {
              width: 1.5rem;
              height: 2rem;
            }
          }
        }
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (inEditMode) {
      <div class="btn-wrapper">
        <button class="icon-btn" (click)="editableRowService.save()" [disabled]="!editableRowService.valid"><mat-icon svgIcon="save"/></button>
        <button class="icon-btn" (click)="editableRowService.cancel()"><mat-icon svgIcon="cancel"/></button>
      </div>
    }

    <!-- SHOW DEFAULT -->
    @else {
      <mat-menu #menu="matMenu">
        @for (option of params.actions; track $index) {
        <button mat-menu-item (click)="option.onAction(params.data!)">{{option.name}}</button>
        }
      </mat-menu>
      <button class="menu-button" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon svgIcon="more-horizontal"></mat-icon>
      </button>
    }
  `,
})
export class ActionCellComponent<
  DATA extends Record<string, any>
> extends CellComponent<DATA, ActionCellProps<DATA>, keyof DATA, DATA[keyof DATA] & boolean> {
}
