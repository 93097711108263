import { inject, Injectable } from "@angular/core";
import { isEqual } from "lodash";
import {
  distinctUntilChanged,
  map,
  Observable,
  scan,
  shareReplay,
  startWith,
  Subject,
} from "rxjs";
import { TableEditableRowService } from "./table-editable-row.service";
import { TableConfirmActionService } from "./table-confirm-action-service";

export type SortEvent = {
  name: string;
  multi: boolean;
};

export type SortDirection = "asc" | "desc";

export type SortOrder = {
  name: string;
  direction: SortDirection;
}[];

export type CurrentSort = {
  name: string;
  direction: SortDirection;
  order: number | null;
};

@Injectable({
  providedIn: "any",
})
export class TableSortService {
  private editableRowService = inject(TableEditableRowService);
  private confirmActionService = inject(TableConfirmActionService);
  private event$ = new Subject<SortEvent>();
  private sortOrder$: Observable<SortOrder> = this.event$.pipe(
    scan((acc, value): SortOrder => {
      const currentDirection: SortDirection | undefined = acc.find(
        (s) => s.name === value.name
      )?.direction;
      if (!currentDirection && !value.multi) {
        return [
          {
            name: value.name,
            direction: "asc",
          },
        ];
      }
      if (currentDirection === "asc" && !value.multi) {
        return [
          {
            name: value.name,
            direction: "desc",
          },
        ];
      }
      if (currentDirection === "desc" && !value.multi) {
        return [];
      }
      if (!currentDirection && value.multi) {
        return [
          ...acc,
          {
            name: value.name,
            direction: "asc",
          },
        ];
      }
      if (currentDirection === "asc" && value.multi) {
        return acc.map((s) =>
          s.name === value.name ? { name: value.name, direction: "desc" } : s
        );
      }
      if (currentDirection === "desc" && value.multi) {
        return acc.filter((s) => s.name !== value.name);
      } else {
        return [];
      }
    }, [] as SortOrder),
    startWith([] as SortOrder),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );

  next(val: SortEvent) {
    if (this?.editableRowService.state.mode === "NONE") {
      this.event$.next(val);
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables sort order while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() => this.event$.next(val)).bind(this),
      });
    }
  }

  getOrder() {
    return this.sortOrder$;
  }

  getCurrentSort(name: string) {
    return this.sortOrder$.pipe(
      map((so) =>
        so
          .map(
            (
              s: SortOrder[number],
              index: number,
              orig: SortOrder
            ): CurrentSort => ({
              ...s,
              order: orig.length > 1 ? index + 1 : null,
            })
          )
          .find((s) => s.name === name)
      )
    );
  }
}
