import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MaskedDatePipe } from "src/app/shared/pipes/maskedDate.pipe";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { NamedDatesComponent } from "../named-dates/named-dates.component";

@Component({
  standalone: true,
  selector: "app-date-picker-button",
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    MaskedDatePipe,
    DatePickerComponent,
    NamedDatesComponent,
  ],
  styles: `
    .wrapper {
      position: relative;
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid black;
    }

    input {
      width: 100%;
      padding: 0.5rem 1.75rem 0.25rem 0.25rem;
      border: none;
      background: white;
      text-align: left;
      border-bottom: 1px solid black;
      font-size: 0.9rem;
      line-height: 1.25rem;

      &:focus {
        outline: none;
      }
    }

    .calendar-icon {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }
  
    .date-popover {
      display: none;
      position: absolute;
    }

    input:focus ~ .date-popover,
    .date-popover:hover {
      z-index: 1;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }

    label {
      position: absolute;
      top: 1rem;
      left: 1rem;
      opacity: 0.75;
      width: 0;
      white-space: nowrap;
    }

    input::placeholder {
      opacity: 0;
    }

    input:not(:placeholder-shown) + label {
      transform: scale(0.75) translateY(-1.25rem);
    }
    `,
  template: `
    <div class="wrapper">
      <mat-icon class="calendar-icon" svgIcon="calendar_month"></mat-icon>
      <input
        type="button"
        [id]="id"
        [placeholder]="'none'"
        [value]="value | maskedDate : namedDates"
      />
      <label [for]="id">{{ label }}</label>
      <div class="date-popover">
        <app-named-dates
          [selectedDate]="value"
          [namedDates]="namedDates"
          (select)="onValueChange($event)"
        ></app-named-dates>
        <app-date-picker
          [value]="value"
          (valueChange)="onValueChange($event)"
        ></app-date-picker>
      </div>
    </div>
  `,
})
export class DatePickerButtonComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() namedDates: NamedDate[] = [];
  @Input() value: Date;

  @Output() valueChange = new EventEmitter<Date>();

  onValueChange(newDate: Date) {
    this.value = newDate;
    this.valueChange.emit(newDate);
  }
}
