import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { DatePickerComponent } from "../date-picker/date-picker.component";
import { NamedDatesComponent } from "../named-dates/named-dates.component";
import { NamedDate } from "src/app/shared/models/namedDate";

@Component({
  standalone: true,
  selector: "app-quick-datepicker",
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    DatePickerComponent,
    NamedDatesComponent,
  ],
  styles: `

    .wrapper {
      position: relative;
      min-width: 2rem;
      height: 2rem;
      border-radius: 0.25rem;
      border: none;
      background: white;
      text-align: left;
      font-size: 0.9rem;
      line-height: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      border: 1px solid black;
      box-shadow: 0px 0px 1px #83e197;
      
      &:hover {
        box-shadow: 0px 0px 5px #83e197;
      }

      &:focus {
        outline: none;
      }
    }

    .date-popover {
      position: absolute;
      z-index: 5;
      display: flex;
      flex-direction: column;
      min-height: 7rem;
      width: 300px;
      right: 0;
      top: calc(100% + 0.5rem);
      border: 1px solid black;
      border-radius: 0;
      padding: 0.5rem 1rem;
      background: white;


      &::before {
        content: ' ';
        border: none;
        border-top: 1px solid black;
        border-right: 1px solid black;
        width: 0.5rem;
        height: 0.5rem;
        transform: rotate(-45deg);
        position: absolute;
        top: calc(-0.25rem - 1px);
        background: linear-gradient(
          45deg, 
          rgba(255,255,255,0) 0%, 
          rgba(255,255,255,0) calc(50% - 1px), 
          rgba(255,255,255,1) calc(50% - 1px), 
          rgba(255,255,255,1) 100%
          );
        right: 1rem;
      }
    }
    `,
  template: `
    <button #btn class="action-btn" (click)="onClick($event)">
      {{ label }}
      <mat-icon class="calendar-icon" svgIcon="calendar_month"></mat-icon>
      @if(open) {
      <div class="date-popover" #pop>
        <app-named-dates
          [selectedDate]="value"
          [namedDates]="namedDates"
          (select)="onValueChange($event)"
        ></app-named-dates>
        <app-date-picker
          [value]="value"
          (valueChange)="onValueChange($event)"
        ></app-date-picker>
      </div>
      }
    </button>
  `,
})
export class QuickDatePickerComponent {
  @Input() label: string;
  @Input() value: string | Date | null | undefined = "";
  @Input() namedDates: NamedDate[] = [];
  @Output() valueChange = new EventEmitter<Date>();

  @ViewChild("btn")
  public btn: ElementRef;

  public elementRef: ElementRef = inject(ElementRef);

  open = false;

  onValueChange(newDate: Date) {
    this.open = false;
    this.valueChange.emit(newDate);
  }

  @HostListener("document:click", ["$event"])
  onClick(evt: MouseEvent) {
    this.open = this.elementRef.nativeElement.contains(evt.target as Node);
  }
}
