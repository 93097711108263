import { map, Observable, scan, shareReplay, startWith, Subject } from "rxjs";
import { FilterParams } from "./http-params";
import { TableEditableRowService } from "src/app/public/table/services/table-editable-row.service";
import { inject, InjectionToken } from "@angular/core";
import { TableConfirmActionService } from "src/app/public/table/services/table-confirm-action-service";

type FilterEvent<T> =
  | {
      type: "ADD";
      value: T;
      multi: boolean;
    }
  | {
      type: "ADD_ALL";
      value: T[];
    }
  | {
      type: "REMOVE";
      value: T;
    }
  | {
      type: "CLEAR";
    };

export class FilterListState<T, K extends Record<string, any> = any> {
  private editableRowService = inject(TableEditableRowService);
  private confirmActionService = inject(TableConfirmActionService);
  private events$ = new Subject<FilterEvent<T>>();
  public list$: Observable<T[]>;
  public params$: Observable<FilterParams>;

  constructor(private name: keyof K, private getId: (item: T) => string) {
    this.list$ = this.events$.pipe(
      scan((acc, evt) => {
        switch (evt.type) {
          case "ADD_ALL": {
            const currentItemsMap: Record<string, T> = acc.reduce(
              (acc, next) => ({ ...acc, [getId(next)]: next }),
              {}
            );
            const incomingItemsMap: Record<string, T> = acc.reduce(
              (acc, next) => ({ ...acc, [getId(next)]: next }),
              {}
            );
            const incomingItemsToAdd = evt.value.filter(
              (i) => !currentItemsMap[getId(i)]
            );
            return [
              ...acc.map((i) => incomingItemsMap[getId(i)] ?? i),
              ...incomingItemsToAdd,
            ];
          }

          case "ADD": {
            if (evt.multi) {
              const updated = acc.map((i) =>
                this.getId(i) === this.getId(evt.value) ? evt.value : i
              );
              const alreadyIn = !!updated.find(
                (i) => this.getId(i) === this.getId(evt.value)
              );
              return alreadyIn ? updated : [...updated, evt.value];
            } else {
              return [evt.value];
            }
          }

          case "REMOVE": {
            return acc.filter((i) => this.getId(i) !== this.getId(evt.value));
          }

          case "CLEAR": {
            return [];
          }

          default:
            return acc;
        }
      }, [] as T[]),
      startWith([] as T[]),
      shareReplay(1)
    );
    this.params$ = this.list$.pipe(
      map((l) => ({
        name: this.name as string,
        values: l.map((i) => ({
          value: this.getId(i),
        })),
      }))
    );
  }

  add(item: T, multi: boolean = false) {
    if (this.editableRowService?.state.mode === "NONE") {
      this.events$.next({ type: "ADD", value: item, multi });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() =>
          this.events$.next({ type: "ADD", value: item, multi })).bind(this),
      });
    }
  }

  addAll(items: T[]) {
    if (this.editableRowService?.state.mode === "NONE") {
      this.events$.next({ type: "ADD_ALL", value: items });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() =>
          this.events$.next({ type: "ADD_ALL", value: items })).bind(this),
      });
    }
  }

  remove(item: T) {
    if (this.editableRowService?.state.mode === "NONE") {
      this.events$.next({ type: "REMOVE", value: item });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() =>
          this.events$.next({ type: "REMOVE", value: item })).bind(this),
      });
    }
  }

  clear() {
    if (this.editableRowService?.state.mode === "NONE") {
      this.events$.next({ type: "CLEAR" });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() => this.events$.next({ type: "CLEAR" })).bind(this),
      });
    }
  }

  getActive(headerName: string): Observable<
    {
      headerName: string;
      operation: string;
      value: string;
      remove: () => void;
    }[]
  > {
    return this.list$.pipe(
      map((list) =>
        list.map((i) => ({
          headerName,
          operation: "=",
          value: this.getId(i),
          remove: (() => this.remove(i)).bind(this),
        }))
      )
    );
  }
}

export type FilterListStateFactory<K extends Record<string, any>> = <T>(
  ...args: ConstructorParameters<typeof FilterListState<T, K>>
) => FilterListState<T, K>;

export const FILTER_LIST_STATE_FACTORY = new InjectionToken(
  "Factory for filtered list states",
  {
    providedIn: "root",
    factory:
      () =>
      <T>(
        name: string,
        getId: (item: T) => string
      ) =>
        new FilterListState<any>(name, getId),
  }
);
