import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "moment";
import { NamedDate } from "src/app/shared/models/namedDate";

@Component({
  standalone: true,
  selector: "app-named-dates",
  styles: `
    :host {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
        margin: 0.5rem;
    }

    button {
        font-size: 0.9rem;
        line-height: 1.25rem;
        border-radius: 0.25rem;
        background-color: transparent;
        border: 1px solid black;
        color: black;
        padding: 0.5rem 0;

        &.selected {
          color: white;
          border-color: transparent;
          background-color: var(--active-blue);
          box-shadow: 1px 0px 5px lightgray;
        }
      }
    `,
  template: ` @if(namedDates) { @for(namedDate of namedDates; track $index){
    <button
      [class.selected]="isSelected(namedDate.value, selectedDate)"
      (click)="handleClick(namedDate.value)"
    >
      {{ namedDate.name }}
    </button>
    } }`,
})
export class NamedDatesComponent {
  @Input() selectedDate?: Date | null | string;
  @Input() namedDates?: NamedDate[];
  @Output() select = new EventEmitter<Date>();

  isSelected(date1: Date | string | null | undefined, date2: Date | string | null | undefined) {
    return moment(date1).isSame(date2, "D");
  }

  handleClick(date: Date) {
    this.select.emit(date)
  }
}
