import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { CurrentSort } from "src/app/shared/utilities/table-sort-observable";

@Component({
    standalone: true,
    imports: [MatIcon],
    selector: '[app-table-header-sort-indicator]',
    template: `
        <!-- ONLY SHOW IF CURREN SORT PROVIDED -->
        @if(currentSort) {
            <!-- DETERMINE ICON -->
            <!-- FOR ASC -->
            @if(currentSort.direction === 'asc'){
            <mat-icon svgIcon="sort-asc" />
            } 
            <!-- FOR DESC -->
            @else {
            <mat-icon svgIcon="sort-desc" />
            }

            <!-- SHOW INDICATION -->
            <span class="order-indicator">
                <!-- SHOW IF MULTI -->
                @if(currentSort.order) {
                {{ currentSort.order }}
                } 
                <!-- SHOW IF SINGLE -->
                @else {
                &nbsp;
                }
            </span>
        }
    `,
    styles: `
        :host {
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
        }

        .order-indicator {
            position: absolute;
            bottom: -0.25rem;
            left: 0.85rem;
            font-size: 0.75rem;
            line-height: 1rem;
            min-width: 1rem;
            padding: 0 0.25rem;
            border-radius: 0.5rem;
            background-color: var(--active-blue);
            color: white;
            z-index: 0;
        }
    `
})
export class TableHeaderSortIndicatorComponent {
    @Input() currentSort?: CurrentSort | null
}