import { DestroyRef, inject, Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";

type ConfimActionEvent = Partial<{
  message: string;
  confirmText: string;
  cancelText: string;
  onConfirm: () => void;
  onCancel: () => void;
}>;

@Injectable({
  providedIn: "root",
})
export class TableConfirmActionService {
  private destroyRef = inject(DestroyRef);
  private subscription = new Subscription();
  private actions$ = new Subject<ConfimActionEvent>();

  public open$ = new BehaviorSubject(false);
  public message = "Are you sure?";
  public confirmText = "Confirm";
  public cancelText = "Cancel";
  public onConfirm = (): void => void 0;
  public onCancel = (): void => void 0;

  constructor() {
    this.subscription.add(
      this.actions$.subscribe((action) => {
        this.message = action.message ?? "Are you sure?";
        this.confirmText = action.confirmText ?? "Confirm";
        this.cancelText = action.cancelText ?? "Cancel";
        this.onConfirm = () => {
          action.onConfirm?.();
          this.open$.next(false);
        };
        this.onCancel = () => {
          action.onCancel?.();
          this.open$.next(false);
        };
        this.open$.next(true);
      })
    );

    this.destroyRef.onDestroy(
      (() => {
        this.subscription.unsubscribe();
      }).bind(this)
    );
  }

  public next(action: ConfimActionEvent) {
    this.actions$.next(action);
  }
}
