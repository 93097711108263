import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  standalone: true,
  selector: "app-page-header",
  imports: [CommonModule],
  template: `<div class="page-header">
    <h1>{{ title }}</h1>
    <div>
      <ng-content/>
    </div>
  </div>`,
  styles: `
    .page-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 6rem);
        margin: 1rem 3rem 0rem 3rem;
        padding: 1rem 0 0.5rem 0;

        h1 {
          margin: 0;
          font-size: 2rem;
        }

        & > div {
            display: flex;
            gap: 1rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
  `,
})
export class PageHeaderComponent {
  @Input({ required: true }) title: string;
}
