import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { Subscription } from "rxjs";
import {
  DateFilterOperator,
  DateFilterState,
  FilterDateState,
} from "src/app/shared/utilities/filter-date-state";
import { DatePickerComponent } from "../../../date-picker/date-picker.component";
import { NamedDatesComponent } from "src/app/public/named-dates/named-dates.component";
import { NamedDate } from "src/app/shared/models/namedDate";

@Component({
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    MatIconModule,
    DatePickerComponent,
    NamedDatesComponent,
  ],
  selector: "app-date-filter",
  styles: `

    .operation-btn-group {
        display:flex;
        flex-wrap: nowrap;
        width: calc(100% - 1rem);
        justify-content: space-between;
        margin: 0.5rem;

        button {
            background: whitesmoke;
            border: 1px solid gainsboro;
            border-radius: 0;
            width: 100%;
            height: 2rem;

            &.selected {
                background: var(--active-blue);
                color: white;
            }

            &:first-child {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }

            &:last-child {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
            }

            &:not(:last-child) {
                border-right: none;
            }
        }
    }

    hr {
        width: calc(100% - 1rem);
        margin: 0.5rem;
    }
    `,
  template: `
    <div class="operation-btn-group">
      <button
        [class.selected]="operationSelected('<')"
        (click)="onOperationClick('<')"
      >
        Before
      </button>
      <button
        [class.selected]="operationSelected('==')"
        (click)="onOperationClick('==')"
      >
        Equals
      </button>
      <button
        [class.selected]="operationSelected('>')"
        (click)="onOperationClick('>')"
      >
        After
      </button>
    </div>
    <hr />
    <app-named-dates
      [selectedDate]="selectedValues?.date"
      [namedDates]="namedDates"
      (select)="onDateChange($event)"
    ></app-named-dates>
    <app-date-picker
      [value]="selectedValues?.date"
      (valueChange)="onDateChange($event)"
    />
  `,
})
export class DateFilterComponent {
  @Input({ required: true }) state: FilterDateState;
  @Input() namedDates?: NamedDate[];

  private subscription = new Subscription();
  public selectedValues?: DateFilterState;

  onOperationClick(op: Exclude<DateFilterOperator, "<=" | ">=">) {
    this.state.setOp(op);
  }

  onDateChange(date: Date) {
    this.state.setDate(date);
  }

  operationSelected(op: Exclude<DateFilterOperator, "<=" | ">=">) {
    return (
      this.selectedValues?.operator === op ||
      (this.selectedValues?.operator === "<=" && ["<", "=="].includes(op)) ||
      (this.selectedValues?.operator === ">=" && [">", "=="].includes(op))
    );
  }

  ngOnInit() {
    this.subscription.add(
      this.state.state$.subscribe((sv) => {
        this.selectedValues = sv;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
