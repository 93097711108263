import {
  filter,
  map,
  Observable,
  scan,
  shareReplay,
  startWith,
  Subject,
} from "rxjs";
import { FilterParams } from "./http-params";
import * as moment from "moment";
import { NamedDate } from "../models/namedDate";
import { TableEditableRowService } from "src/app/public/table/services/table-editable-row.service";
import { inject, InjectionToken } from "@angular/core";
import { TableConfirmActionService } from "src/app/public/table/services/table-confirm-action-service";

export type DateFilterOperator = "==" | ">" | "<" | ">=" | "<=";

type DateFilterAction =
  | {
      type: "SET_OP";
      operator: Exclude<DateFilterOperator, "<=" | ">=">;
    }
  | {
      type: "SET_DATE";
      date: Date | null;
    }
  | {
      type: "CLEAR_OP";
    }
  | {
      type: "CLEAR_ALL";
    };

export type DateFilterState = {
  date: Date | null;
  operator: DateFilterOperator | null;
};

const initialState: DateFilterState = {
  date: null,
  operator: "==",
};

const rectifyOps = (
  current: DateFilterOperator | null,
  next: DateFilterOperator
) =>
  (
    [
      [() => current === next, () => null],
      [() => current === "<=" && next === "<", () => "=="],
      [() => current === "<=" && next === "==", () => "<"],
      [() => current === "<" && next === "==", () => "<="],
      [() => current === "==" && next === "<", () => "<="],
      [() => current === ">=" && next === ">", () => "=="],
      [() => current === ">=" && next === "==", () => ">"],
      [() => current === ">" && next === "==", () => ">="],
      [() => current === "==" && next === ">", () => ">="],
      [() => true, () => next],
    ] as [() => boolean, () => DateFilterOperator | null][]
  ).find(([cond]) => cond())?.[1]() ?? null;

export class FilterDateState<T extends Record<string, any> = any> {
  private editableRowService = inject(TableEditableRowService);
  private confirmActionService = inject(TableConfirmActionService);
  private actions$ = new Subject<DateFilterAction>();
  public state$: Observable<DateFilterState> = this.actions$.pipe(
    scan((state, action) => {
      switch (action.type) {
        case "SET_OP": {
          return {
            ...state,
            operator: rectifyOps(state.operator, action.operator),
          };
        }

        case "SET_DATE": {
          return {
            ...state,
            date: action.date,
          };
        }

        case "CLEAR_OP": {
          return {
            ...state,
            operator: null,
          };
        }

        case "CLEAR_ALL": {
          return initialState;
        }

        default: {
          return state;
        }
      }
    }, initialState),
    startWith(initialState),
    shareReplay(1)
  );
  public params$: Observable<FilterParams> = this.state$.pipe(
    map((state) => ({
      name: this.name as string,
      values:
        state.date && state.operator
          ? [
              {
                value: moment(state.date).toISOString(false),
                operation: state.operator,
              },
            ]
          : [],
    }))
  );

  constructor(private name: keyof T, private namedDates?: NamedDate[]) {}

  setOp(operator: Exclude<DateFilterOperator, ">=" | "<=">) {
    if (this.editableRowService?.state.mode === "NONE") {
      this.actions$.next({
        type: "SET_OP",
        operator,
      });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() =>
          this.actions$.next({
            type: "SET_OP",
            operator,
          })).bind(this),
      });
    }
  }

  clearOp() {
    if (this.editableRowService?.state.mode === "NONE") {
      this.actions$.next({
        type: "CLEAR_OP",
      });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() =>
          this.actions$.next({
            type: "CLEAR_OP",
          })).bind(this),
      });
    }
  }

  setDate(date: Date) {
    if (this.editableRowService?.state.mode === "NONE") {
      this.actions$.next({ type: "SET_DATE", date });
    } else {
      this.confirmActionService.next({
        message:
          "Modifying the tables filters while an updating/creating data will result in your changes being canceled. Would you like to continue?",
        onConfirm: (() => this.actions$.next({ type: "SET_DATE", date })).bind(
          this
        ),
      });
    }
  }

  getActive(headerName: string): Observable<
    {
      headerName: string;
      operation: string;
      value: string;
      remove: () => void;
    }[]
  > {
    return this.state$.pipe(
      map((state) =>
        !!state.date && !!state.operator
          ? [
              {
                headerName,
                operation: state
                  .operator!.replace("==", "=")
                  .replace("<=", "≤")
                  .replace(">=", "≥"),
                value:
                  this.namedDates?.find((nd) =>
                    moment(nd.value).isSame(state.date, "D")
                  )?.name ?? moment(state.date).format("YYYY-MM-DD"),
                remove: (() => this.actions$.next({ type: "CLEAR_ALL" })).bind(
                  this
                ),
              },
            ]
          : []
      )
    );
  }
}

export type FilterDateStateFactory<T extends Record<string, any>> = (
  ...args: ConstructorParameters<typeof FilterDateState<T>>
) => FilterDateState<T>;

export const FILTER_DATE_STATE_FACTORY = new InjectionToken(
  "Factory for filtered date states",
  {
    providedIn: "root",
    factory:
      () =>
      <T extends Record<string, any>>(
        name: string,
        namedDates?: NamedDate[]
      ) =>
        new FilterDateState<T>(name, namedDates),
  }
);
