import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-select-menu-list-clear-button-item]",
  template: `
    <span>Selected</span><button (click)="click.emit($event)"><ng-content>Clear All</ng-content></button>
  `,
  styles: `
    :host {
      padding: 0.5rem 1rem;
      display: flex;
      justify-content: space-between;
    }

    span {
        font-weight: normal;
    }

    button {
        border-radius: 0.25rem;
        border: 1px solid var(--active-blue);
        background: transparent;
        color: var(--active-blue);

        &:hover {
            background: gainsboro;
        }
    }
  `,
})
export class SelectMenuListClearButtonItemComponent {
  @Output() click = new EventEmitter<MouseEvent>();
}
