import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  NEVER,
  Observable,
  shareReplay,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { Filters } from "./table.types";
import { MDMAJobStatus } from "../settlements/service/settlements.types";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { APIEnvelope } from "src/app/shared/models/api";
import { SettlementsService } from "../settlements/service/settlements.service";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { SortOrder } from "src/app/shared/utilities/table-sort-observable";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { TableFilterService } from "./services/table-filter.service";
import { TableSortService } from "./services/table-sort.service";
import { MDMAAggregationsColumnDefinition } from "./definitions/mdma-aggregations-column-definition";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import {
  FILTER_DATE_STATE_FACTORY,
  FilterDateStateFactory,
} from "src/app/shared/utilities/filter-date-state";
import { FILTER_LIST_STATE_FACTORY } from "src/app/shared/utilities/filter-list-state";

@Component({
  standalone: true,
  selector: "app-mdma-table-aggregations-table",
  imports: [AgGridAngular, TablePaginationComponent, ActiveFilterListComponent],
  providers: [TableSortService, TableFilterService],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    app-table-pagination {
        margin-top: 1rem;
    }
  `,
  template: `
    <app-active-filter-list [columnDefs]="columnDefs"></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: calc(100vh - 250px)"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressNoRowsOverlay]="true"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class MDMAAggregationsTableComponent implements OnDestroy {
  settlementsService = inject(SettlementsService);
  referenceDataService = inject(ReferenceDataService);
  filterDateStateFactory = inject(FILTER_DATE_STATE_FACTORY);
  filterListStateFactory = inject(FILTER_LIST_STATE_FACTORY);

  @Output() public pageNumberChange = new EventEmitter<number>();
  @Output() public pageSizeChange = new EventEmitter<number>();
  @Output() public sortOrderChange = new EventEmitter<SortOrder>();
  @Output() public filtersChange = new EventEmitter<Filters>();
  @Output() public create = new EventEmitter<MDMAJobStatus>();
  @Output() public view = new EventEmitter<MDMAJobStatus>();

  private subscription = new Subscription();
  public gridApi: GridApi;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageSize, pageNumber]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  sortService = inject(TableSortService);
  filterService = inject(TableFilterService);

  // DATA
  public data$: Observable<APIEnvelope<MDMAJobStatus>> = combineLatest([
    this.paginationParams$,
    this.filterService.state$.pipe(
      tap(() => {
        if (this.pageNumber !== 0) {
          this.pageNumber$.next(0);
        }
      })
    ),
    this.sortService.getOrder(),
  ]).pipe(
    tap(() => {
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.settlementsService.getJobs(page, filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = { error: true };
      const nodeData: IRowNode[] = [];
      this.gridApi?.forEachNode((node) => nodeData.push(node.data));
      this.gridApi?.applyTransaction({
        remove: nodeData,
      });
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<MDMAJobStatus> = (data) => data.data.job_uid;

  init() {
    this.subscription.add(
      this.pageNumber$.subscribe((pageNumber) => {
        this.pageNumber = pageNumber;
        this.pageNumberChange.emit(pageNumber);
      })
    );
    this.subscription.add(
      this.pageSize$.subscribe((pageSize) => {
        this.pageSize = pageSize;
        this.pageSizeChange.emit(pageSize);
      })
    );
    this.subscription.add(
      this.data$.subscribe((res) => {
        this.totalResults = res.total;
        const nodeData: unknown[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node.data));
        this.gridApi?.applyTransaction({
          remove: nodeData,
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
      })
    );
  }

  destroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };

  public mdmaAggregationsColumnDefinition =
    new MDMAAggregationsColumnDefinition(
      this.filterDateStateFactory,
      this.filterListStateFactory,
      this.referenceDataService,
      [
        {
          name: "View",
          onAction: (data: MDMAJobStatus) => this.view.emit(data),
        },
        {
          name: "Run Again",
          onAction: (data: MDMAJobStatus) => this.create.emit(data),
        },
      ]
    );
  public columnDefs: ColDef[] =
    this.mdmaAggregationsColumnDefinition.getColDefs();
  public filters = this.mdmaAggregationsColumnDefinition.filters;
}
