import { DestroyRef, inject, Injectable } from "@angular/core";
import { GridApi, IRowNode } from "ag-grid-community";
import { ReplaySubject, scan, startWith, Subscription } from "rxjs";

type State<T> = {
  index: number;
  mode: "CREATE" | "UPDATE" | "DELETE" | "NONE";
  data: Partial<T>;
  openField: keyof T | undefined;
  afterProcess?: () => void;
};

type SetEditRowAction<T> = {
  type: "SET_EDIT_ROW";
  payload: {
    index: number;
    mode: State<T>["mode"];
    data: Partial<T>;
    afterProcess: State<T>["afterProcess"];
  };
};

type UpdateDateValueAction<T, K extends keyof T> = {
  type: "UPDATE_DATA_VALUE";
  payload: {
    val: T[K];
    key?: K;
  };
};

type OpenFieldAction<T, K extends keyof T> = {
  type: "OPEN_FIELD";
  payload: {
    key?: K;
  };
};

type Action<T, K extends keyof T> =
  | SetEditRowAction<T>
  | UpdateDateValueAction<T, K>
  | OpenFieldAction<T, K>;

function reducer<T extends Record<string, any>, K extends keyof T>(
  this: TableEditableRowService<T>,
  state: State<T>,
  action: Action<T, K>
): State<T> {
  switch (action.type) {
    case "SET_EDIT_ROW": {
      return {
        index: action.payload.index,
        mode: action.payload.mode,
        data: action.payload.data,
        afterProcess: action.payload.afterProcess,
        openField: undefined,
      };
    }

    case "UPDATE_DATA_VALUE": {
      const key = action.payload.key ?? state.openField;
      return {
        ...state,
        afterProcess: undefined,
        data: {
          ...state.data,
          ...(key ? { [key]: action.payload.val } : {}),
        },
      };
    }

    case "OPEN_FIELD": {
      return {
        ...state,
        afterProcess: undefined,
        openField: action.payload.key,
      };
    }

    default: {
      return state;
    }
  }
}

@Injectable({
  providedIn: "any",
})
export class TableEditableRowService<T extends Record<string, any>> {
  private destroyRef = inject(DestroyRef);
  private subscription = new Subscription();

  private readonly initialState: State<T> = {
    index: -1,
    mode: "NONE",
    data: {},
    openField: undefined,
  };

  private action$ = new ReplaySubject<Action<T, keyof T>>(1);
  private state$ = this.action$.pipe(
    scan(reducer, this.initialState),
    startWith(this.initialState)
  );

  public state: State<T> = this.initialState;
  public valid = false;

  constructor() {
    this.subscription.add(
      this.state$.subscribe((state) => {
        this.state = state;
        this.valid = this.validator?.(state.data) ?? true;
        state.afterProcess?.();
      })
    );
    this.destroyRef.onDestroy(
      (() => {
        this.subscription.unsubscribe();
      }).bind(this)
    );
  }

  public gridApi: GridApi<Partial<T>>;
  public setGridApi(gridApi: GridApi<Partial<T>>) {
    this.gridApi = gridApi;
    return this;
  }

  private validator: (val: Partial<T>) => boolean;
  public setValidator(validator: (val: Partial<T>) => boolean) {
    this.validator = validator;
    return this;
  }

  private onCancel = (): void => void 0;
  public addCancelListener(fn: () => void) {
    this.onCancel = fn;
    return this;
  }

  private onSave = (val: Partial<T>): void => void 0;
  public addSaveListener(fn: (val: Partial<T>) => void) {
    this.onSave = fn;
    return this;
  }

  openField(name: keyof T) {
    this.action$.next({
      type: "OPEN_FIELD",
      payload: {
        key: this.state.openField === name ? undefined : name,
      },
    });
  }

  updateValue<K extends keyof T>(key: K, val: T[K]) {
    this.action$.next({
      type: "UPDATE_DATA_VALUE",
      payload: {
        key: key,
        val: val,
      },
    });
  }

  private cancelLastEdit(): State<T> {
    const lastState = { ...this.state };
    if (this.state.index >= 0) {
      const node = this.gridApi.getDisplayedRowAtIndex(this.state.index);
      if (node) {
        if (this.state.mode === "CREATE") {
          this.gridApi.applyTransaction({
            remove: [node.data ?? {}],
          });
        }
        if (this.state.mode === "UPDATE") {
        }
      }
    }
    return lastState;
  }

  create(initialValue: Partial<T> = {}, index: number = 0) {
    const lastState = this.cancelLastEdit();
    this.action$.next({
      type: "SET_EDIT_ROW",
      payload: {
        index,
        data: initialValue,
        mode: "CREATE",
        afterProcess: (() => {
          const indexToRedraw =
            lastState.index >= 0
              ? lastState.index > index
                ? lastState.index + 1
                : lastState.index
              : -1;
          const node =
            indexToRedraw >= 0
              ? this.gridApi.getDisplayedRowAtIndex(indexToRedraw)
              : undefined;
          if (node) {
            this.gridApi.redrawRows({
              rowNodes: [node].filter(Boolean) as IRowNode<T>[],
            });
          }
          this.gridApi.applyTransaction({
            addIndex: index,
            add: [initialValue],
          });
          this.gridApi.ensureIndexVisible(index);
        }).bind(this),
      },
    });
  }

  update(index: number = 0) {
    const lastState = this.cancelLastEdit();
    const indexToRedraw = lastState.index;
    const lastNode =
      indexToRedraw >= 0
        ? this.gridApi.getDisplayedRowAtIndex(indexToRedraw)
        : undefined;
    const node = this.gridApi.getDisplayedRowAtIndex(index);
    if (!node) {
      return undefined;
    }
    this.action$.next({
      type: "SET_EDIT_ROW",
      payload: {
        index: node.rowIndex ?? index,
        data: node.data ?? {},
        mode: "UPDATE",
        afterProcess: (() => {
          this.gridApi.redrawRows({
            rowNodes: [lastNode, node].filter(Boolean) as IRowNode<T>[],
          });
        }).bind(this),
      },
    });
  }

  cancel() {
    const lastState = this.cancelLastEdit();
    this.action$.next({
      type: "SET_EDIT_ROW",
      payload: {
        ...this.initialState,
        afterProcess: (() => {
          const indexToRedraw = lastState.index;
          const lastNode =
            indexToRedraw >= 0
              ? this.gridApi.getDisplayedRowAtIndex(indexToRedraw)
              : undefined;
          this.gridApi.redrawRows({
            rowNodes: [lastNode].filter(Boolean) as IRowNode<T>[],
          });
          this.onCancel?.();
        }).bind(this),
      },
    });
  }

  save() {
    this.onSave?.(this.state.data);
  }
}
