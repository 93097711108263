import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
} from "@angular/core";
import { CellComponent } from "./cell.component";
import { EditableCellButtonComponent } from "./editable-cell-button/editable-cell-button.component";
import { TableCellPanelComponent } from "./table-cell-panel/table-cell-panel.component";
import * as moment from "moment";
import { DatePickerComponent } from "../../date-picker/date-picker.component";
import { NamedDatesComponent } from "../../named-dates/named-dates.component";
import { NamedDate } from "src/app/shared/models/namedDate";
import { MaskedDatePipe } from "src/app/shared/pipes/maskedDate.pipe";

type DateCellComponentProps = {
  editable?: boolean;
  namedDates?: NamedDate[];
};

@Component({
  standalone: true,
  imports: [
    EditableCellButtonComponent,
    TableCellPanelComponent,
    DatePickerComponent,
    NamedDatesComponent,
    MaskedDatePipe,
  ],
  styles: `
        :host {
            position: relative;
        }
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit && params.editable !== false) {
    <div app-editable-cell-button (select)="selectCellForEdit()">
      {{ currentValue | maskedDate : params.namedDates }}
    </div>

    <div
      #panel
      app-table-cell-panel
      [width]="'15rem'"
      [show]="editableRowService.state.openField === field"
    >
      <app-named-dates
        [selectedDate]="currentValue"
        [namedDates]="params.namedDates"
        (select)="setValue($event)"
      ></app-named-dates>
      <app-date-picker
        [value]="currentValue"
        (valueChange)="setValue($event)"
      ></app-date-picker>
    </div>
    }

    <!-- SHOW line to indicate uneditable  -->
    @else if(this.canEdit && params.editable === false) { - }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    {{ params.value | maskedDate : params.namedDates }}
    }
  `,
})
export class DateCellComponent<
  DATA extends Record<string, unknown>
> extends CellComponent<
  DATA,
  DateCellComponentProps,
  keyof DATA,
  DATA[keyof DATA] & string
> {
  @ViewChild("panel", { read: ElementRef })
  panelRef: ElementRef<HTMLDivElement>;
  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.field &&
      this.editableRowService.state.openField === this.field &&
      this.panelRef &&
      !this.panelRef.nativeElement.contains(event.target as Node)
    ) {
      this.unselectCellForEdit();
    }
  }

  setValue(val: Date) {
    if (this.field) {
      this.editableRowService.updateValue(
        this.field as string,
        moment(val).toISOString(true)
      );
    }
  }
}
