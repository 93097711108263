import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDatepickerModule,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { DateStructPipe } from "src/app/shared/pipes/dateStruct.pipe";
import { ToDatePipe } from "src/app/shared/pipes/toDate.pipe";

@Component({
  standalone: true,
  imports: [NgbDatepickerModule, FormsModule, ToDatePipe, DateStructPipe],
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }],
  selector: "app-date-picker",
  styles: `
    :host {
      display: flex;
      justify-content: center;
    }

    ngb-datepicker.app-datepicker::ng-deep {
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 0.9rem;
      line-height: 1.25rem;

      .bg-primary {
        background-color: var(--active-blue) !important;
      }

      .ngb-dp-header {
        background-color: transparent;

        .ngb-dp-arrow{
          display: none;
        }
      }

      .ngb-dp-months {
        display: block;
        width: 100%;

        .ngb-dp-week {
          justify-content: space-between;
          display: none;

          &:has(.ngb-dp-day:not(.hidden)) {
            display: flex;
          }
        }
      }

      .ngb-dp-weekdays {
        display: flex !important; 
        background-color: transparent;
        border: none;

        .ngb-dp-weekday {
          color: black;
          font-weight: 700;
        }
      }

      .ngb-dp-month:first-child .ngb-dp-week {
        padding-left: 0; 
      }

      .ngb-dp-month:last-child .ngb-dp-week {
        padding-right:0; 
      }

      .ngb-dp-navigation-select {
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        select {
          width: 100%;
          padding: 0.5rem 1rem 0.25rem 1rem;
          position: relative;
          width: 100%;
          border: none;
          background: white;
          text-align: center;
          border-bottom: 1px solid black;
          border-radius: 0;
          -moz-appearance:none; /* Firefox */
          -webkit-appearance:none; /* Safari and Chrome */
          appearance:none;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          
        }
      }

      [ngbDatepickerDayView] {
        border-radius: 50%;
      }
      
      ngb-datepicker-month {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin-top: 0.75rem;
      }
    }
    `,
  template: ` <ngb-datepicker
    #dp
    class="app-datepicker"
    [minDate]="minDate"
    [startDate]="value | toDate | dateStruct"
    [ngModel]="value | toDate"
    (ngModelChange)="valueChange.emit($event)"
    outsideDays="hidden"
  />`,
})
export class DatePickerComponent {
  minDate: NgbDateStruct = {
    year: 2007,
    month: 1,
    day: 1
  }

  @Input()
  public value?: Date | string | null;

  @Output()
  valueChange = new EventEmitter<Date>();
}
