import { Component, Input } from "@angular/core";
import { TableHeaderSortIndicatorComponent } from "../table-header-sort-indicator/table-header-sort-indicator.component";
import { CurrentSort } from "src/app/shared/utilities/table-sort-observable";

@Component({
    standalone: true,
    imports: [TableHeaderSortIndicatorComponent],
    selector: '[app-table-header-sort-button]',
    template: `
      <span class="header-name">{{ label }}</span>

      <!-- Show SORT INDICATOR -->
      <span 
        app-table-header-sort-indicator 
        [currentSort]="currentSort">
      </span>
    `,
    styles: `
        :host {            
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1.5rem);
            align-items: center;
            background-color: transparent;
            border: none;
            padding: 0;
            gap: 0.25rem;
            white-space: nowrap;
        }

        .header-name {
            white-space: break-spaces;
            text-align: start;
            text-overflow: ellipsis;
            overflow: hidden;
            max-height: 2.5rem;
            line-height: 1.2rem;
        }
    `
})
export class TableHeaderSortButtonComponent {
    @Input()
    public label: string 

    @Input()
    public currentSort?: CurrentSort | null;
}