import { AsyncPipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { ColDef } from "ag-grid-community";
import { combineLatest, map, Observable } from "rxjs";
import { FilterDateState } from "src/app/shared/utilities/filter-date-state";
import { FilterListState } from "src/app/shared/utilities/filter-list-state";

@Component({
  standalone: true,
  imports: [AsyncPipe, MatIcon],
  selector: "app-active-filter-list",
  styles: `
    ul {
      min-height: 3rem;
      margin-bottom: 0.5rem;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      gap: 0.5rem;
      padding: 0;
    }
  
    li {
      display: flex;
      gap: 0.25rem;
      max-height: 1.25rem;
      height: 1.25rem;
      line-height: 1.25rem;
      border-radius: 0.5rem;
      border: none;
      background: gainsboro;
      padding: 0 0.5rem;
    }

    button {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
    }

    .icon {
      max-height: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin: 0;
    }
  `,
  template: `<ul>
    @for(i of active$ | async; track $index){
    <li>
      <span>{{ i.headerName }}&nbsp;{{ i.operation }}&nbsp;{{ i.value }}</span
      ><button (click)="i.remove()">
        <mat-icon class="icon" svgIcon="close" />
      </button>
    </li>
    }
  </ul>`,
})
export class ActiveFilterListComponent {
  public active$: Observable<
    {
      headerName: string;
      operation: string;
      value: string;
      remove: () => void;
    }[]
  >;
  @Input() public set columnDefs(val: ColDef[]) {
    this.active$ = combineLatest(
      val
        .filter((v) => !!v.headerName && !!v.headerComponentParams?.filterState)
        .map((v) =>
          (
            v.headerComponentParams!.filterState! as
              | FilterDateState
              | FilterListState<any>
          ).getActive(v.headerName!)
        )
    ).pipe(
      map((actives) => actives.flat(1)),
    );
  }
}
