import { inject, Injectable } from "@angular/core";
import { isEqual } from "lodash";
import {
  distinctUntilChanged,
  map,
  Observable,
  ReplaySubject,
  scan,
  shareReplay,
  startWith,
} from "rxjs";
import { FilterParams } from "src/app/shared/utilities/http-params";

@Injectable({
  providedIn: "any",
})
export class TableFilterService {
  private openFilterNameAction$ = new ReplaySubject<string>(1);
  private openFilterName$: Observable<string | undefined> =
    this.openFilterNameAction$.pipe(
      scan(
        (prev: string | undefined, next) => (prev === next ? undefined : next),
        undefined
      ),
      startWith(undefined),
      shareReplay(1)
    );
  private action$ = new ReplaySubject<FilterParams>(1);
  public state$: Observable<FilterParams[]> = this.action$.asObservable().pipe(
    scan(
      (list, param) =>
        list.find((i) => i.name === param.name)
          ? list.map((i) => (i.name === param.name ? param : i))
          : [...list, param],
      <FilterParams[]>[]
    ),
    startWith(<FilterParams[]>[]),
    map((params: FilterParams[]) => params.filter((p) => p.values.length)),
    distinctUntilChanged(isEqual),
    shareReplay(1)
  );

  update(p: FilterParams) {
    this.action$.next(p);
  }

  toggle(name: string) {
    this.openFilterNameAction$.next(name);
  }

  isOpen(name: string) {
    return this.openFilterName$.pipe(map((n) => n === name));
  }
}
