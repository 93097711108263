import { Component } from "@angular/core";


@Component({
    standalone: true,
    selector: '[app-select-menu-list-paging-item]',
    template: `
       <span class="display-paging-text">...loading</span>
    `,
    styles: `
    @keyframes pulse {
      from { opacity: 1; }
      50% { opacity: 0; }
      to { opacity: 1; }
    }

    :host {
      margin: 0;
      padding: 0;
    }

    .display-paging-text {
        padding: 0.75rem 0 0.75rem 1rem;
        font-weight: normal;
        color: #2bb348;
        animation: pulse 2s linear infinite;;
    } 
    `
})
export class SelectMenuListPagingComponent{
}