import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatIcon } from "@angular/material/icon";

@Component({
    standalone: true,
    imports: [MatIcon],
    selector: '[app-table-header-filter-button]',
    styles: `
        :host {
            position: relative;
            width: 1.5rem;
            height: 1.5rem;
            background: none;
            border: none;
        }

        .filter-indicator {
            position: absolute;
            bottom: -0.25rem;
            left: 0.85rem;
            font-size: 0.75rem;
            line-height: 1rem;
            min-width: 1rem;
            padding: 0 0.25rem;
            border-radius: 0.5rem;
            background-color: var(--active-blue);
            color: white;
            z-index: 0;
        }
    `,
    template: `
        <mat-icon svgIcon="filter" />
        <!-- SHOW IF FILTERS -->
        @if(filterCount) {
            <span class="filter-indicator">
                <!-- SHOW FOR MORE THAN ONE FILTER -->
                @if(filterCount > 1) { {{ filterCount }} } 
                <!-- SHOW FOR 1 FILTER -->
                @else { &nbsp; }
            </span>
        }
      `,
})
export class TableHeaderFilterButton {
    @Input() 
    public filterCount?: number | null;
}