import * as moment from "moment"

export type NamedDate = {
    name: string,
    value: Date
}

export const NON_TERMINATING_NAMED_DATE: NamedDate = {
    name: 'Non-terminating',
    value: moment({
        year: 9999,
        month: 0,
        day: 1
    }).startOf('D').toDate()
}