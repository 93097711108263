import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  NEVER,
  Observable,
  shareReplay,
  Subject,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  ColDef,
  GetRowIdFunc,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { APIEnvelope } from "src/app/shared/models/api";
import { ReferenceDataService } from "../reference-data/reference-data.service";
import { AgGridAngular } from "ag-grid-angular";
import { TablePaginationComponent } from "./table-pagination/table-pagination.component";
import { TableLoadingOverlayComponent } from "./overlays/table-loading-overlay.component";
import { TableNoDataOverlayComponent } from "./overlays/table-no-data-overlay.component";
import { SpaOverrideService } from "../spah-overrides/spah-override.service";
import { SpaOverride } from "src/app/shared/models/spaOverride";
import { SpaOverrideAction } from "../spah-overrides/spah-override.types";
import { SpahOverrideColumnDefinition } from "./definitions/spah-overrides-column-definition";
import { TableSortService } from "./services/table-sort.service";
import { TableFilterService } from "./services/table-filter.service";
import { TableEditableRowService } from "./services/table-editable-row.service";
import * as moment from "moment";
import { ActiveFilterListComponent } from "./filters/active-filter-list/active-filter-list.component";
import { TableConfirmActionComponent } from "./table-confirm-action/table-confirm-action.component";
import { FILTER_DATE_STATE_FACTORY } from "src/app/shared/utilities/filter-date-state";
import { FILTER_LIST_STATE_FACTORY } from "src/app/shared/utilities/filter-list-state";

@Component({
  standalone: true,
  selector: "app-spah-overrides-table",
  imports: [
    AgGridAngular,
    TablePaginationComponent,
    ActiveFilterListComponent,
    TableConfirmActionComponent,
  ],
  providers: [TableSortService, TableFilterService, TableEditableRowService],
  styles: `
    :host {
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    app-table-pagination {
        margin-top: 1rem;
    }
  `,
  template: `
    <app-table-confirm-action></app-table-confirm-action>
    <app-active-filter-list [columnDefs]="columnDefs"></app-active-filter-list>
    <ag-grid-angular
      class="ag-theme-quartz app-table"
      style="height: calc(100vh - 250px)"
      [getRowId]="getRowId"
      [columnDefs]="columnDefs"
      [rowData]="[]"
      [suppressPaginationPanel]="true"
      [suppressDragLeaveHidesColumns]="true"
      [loadingOverlayComponent]="loadingOverlayComponent"
      [noRowsOverlayComponent]="noRowsOverlayComponent"
      [noRowsOverlayComponentParams]="noRowsOverlayParams"
      [pagination]="false"
      [enableCellTextSelection]="true"
      (gridReady)="onGridReady($event)"
    />
    <app-table-pagination
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalResults]="totalResults"
      (pageNumberChange)="pageNumber$.next($event)"
      (pageSizeChange)="pageSize$.next($event)"
    />
  `,
})
export class SpahOverridesTableComponent implements OnDestroy {
  spaOverrideService = inject(SpaOverrideService);
  referenceDataService = inject(ReferenceDataService);
  sortService = inject(TableSortService);
  filterService = inject(TableFilterService);
  editableRowService = inject(TableEditableRowService);
  filterDateStateFactory = inject(FILTER_DATE_STATE_FACTORY);
  filterListStateFactory = inject(FILTER_LIST_STATE_FACTORY);

  @Input() public actions$: Subject<SpaOverrideAction>;

  @Output() public create = new EventEmitter<Partial<SpaOverride>>();
  @Output() public update = new EventEmitter<{
    id: string;
    effective_date: Date;
    data: Partial<SpaOverride>;
  }>();
  @Output() public delete = new EventEmitter<{
    id: string;
    effective_date: Date;
  }>();
  @Output() public loading = new EventEmitter<boolean>();

  private subscription = new Subscription();
  private refreshTrigger$ = new BehaviorSubject<void>(void 0);
  public gridApi: GridApi<Partial<SpaOverride>>;

  // PAGINATION
  public totalResults: number = 0;
  public pageNumber: number = 0;
  public pageSize: number = 1000;
  public pageSize$ = new BehaviorSubject(25);
  public pageNumber$ = new BehaviorSubject(0);
  public paginationParams$ = combineLatest([
    this.pageNumber$,
    this.pageSize$,
  ]).pipe(
    map(([pageNumber, pageSize]) => ({ pageNumber, pageSize })),
    shareReplay(1)
  );

  // DATA
  public data$: Observable<APIEnvelope<SpaOverride>> = combineLatest([
    this.paginationParams$,
    this.filterService.state$.pipe(
      tap(() => {
        if (this.pageNumber !== 0) {
          this.pageNumber$.next(0);
        }
      })
    ),
    this.sortService.getOrder(),
    this.refreshTrigger$,
  ]).pipe(
    tap(() => {
      this.loading.emit(true);
      this.gridApi.showLoadingOverlay();
    }),
    switchMap(([page, filters, sortOrder]) => {
      return this.spaOverrideService.getOverrides(page, filters, sortOrder);
    }),
    catchError(() => {
      this.noRowsOverlayParams = { error: true };
      const nodeData: IRowNode<Partial<SpaOverride>>[] = [];
      this.gridApi?.forEachNode((node) => node.data && nodeData.push(node));
      this.gridApi?.applyTransaction({
        remove: nodeData.map((n) => n.data!),
      });
      this.loading.emit(false);
      this.gridApi.showNoRowsOverlay();
      return NEVER;
    }),
    shareReplay(1)
  );

  getRowId: GetRowIdFunc<Partial<SpaOverride>> = (data) =>
    this.getDataId(data.data);

  getDataId = (data: Partial<SpaOverride> | undefined) =>
    data?.service_point_local_def_uid ?? "";

  init() {
    this.editableRowService
      .setGridApi(this.gridApi)
      .setValidator((data) =>
        [
          "service_point_id",
          "effective_date",
          "termination_date",
          "ldc_code",
          "eldc_code",
          "profile_class_code",
          "retailer_code",
          "supply_class_code",
          "meter_type_code",
          "loss_class_code",
          "location_code",
          "cpnode_code",
          "service_point_source_code",
          "weather_sensitive_flag",
          "bmg_flag",
          "tie_flag",
          "net_meter_rider_flag",
          "usage_ingestion_block_flag",
        ].every((key) => data[key] !== undefined)
      );

    this.subscription.add(
      this.data$.subscribe((res) => {
        this.editableRowService.cancel();
        this.totalResults = res.total;
        const nodeData: IRowNode<Partial<SpaOverride>>[] = [];
        this.gridApi?.forEachNode((node) => nodeData.push(node));
        this.gridApi?.applyTransaction({
          remove: nodeData.map((n) => n.data!),
          add: res.results,
        });
        this.gridApi.hideOverlay();
        if (!res.results.length) {
          this.gridApi?.forEachNode((node) => nodeData.push(node));
          this.gridApi?.applyTransaction({
            remove: nodeData.map((n) => n.data!),
          });
          this.noRowsOverlayParams = { error: false };
          this.gridApi.showNoRowsOverlay();
        }
        this.loading.emit(false);
      })
    );

    if (this.actions$) {
      this.subscription.add(
        this.actions$.subscribe((action) => {
          switch (action.type) {
            case "CREATE": {
              this.onCreateAction();
              break;
            }

            case "REFRESH": {
              this.refreshTrigger$.next();
              break;
            }
          }
        })
      );
    }
  }

  destroy() {
    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.init();
  }

  ngOnDestroy() {
    this.destroy();
  }

  onCreateAction() {
    this.editableRowService
      .addCancelListener(() => void 0)
      .addSaveListener(
        ((data: Partial<SpaOverride>) => this.create.emit(data)).bind(this)
      )
      .create();
  }

  onCloneAction(item: SpaOverride) {
    const node = this.gridApi.getRowNode(this.getDataId(item));
    if (node) {
      this.editableRowService
        .addCancelListener(() => void 0)
        .addSaveListener(
          ((data: Partial<SpaOverride>) => this.create.emit(data)).bind(this)
        )
        .create(
          {
            ...(node.data ?? {}),
            service_point_local_def_uid: undefined,
            service_point_id: undefined,
            effective_date: undefined,
            termination_date: undefined,
            create_time: undefined,
            created_by: undefined,
            last_update_time: undefined,
            last_updated_by: undefined,
          },
          (node.rowIndex ?? 0) + 1
        );
    }
  }

  onUpdateAction(item: SpaOverride) {
    const node = this.gridApi.getRowNode(this.getDataId(item));
    if (node) {
      this.editableRowService
        .addCancelListener(() => void 0)
        .addSaveListener(
          ((data: Partial<SpaOverride>) =>
            this.update.emit({
              data,
              id: data.service_point_id ?? "",
              effective_date: moment(node.data?.effective_date).toDate(),
            })).bind(this)
        )
        .update(node.rowIndex ?? 0);
    }
  }

  // columns
  public loadingOverlayComponent = TableLoadingOverlayComponent;
  public noRowsOverlayComponent = TableNoDataOverlayComponent;
  public noRowsOverlayParams = { error: false };
  public spahOverrideColumnDefinition = new SpahOverrideColumnDefinition(
    this.filterDateStateFactory,
    this.filterListStateFactory,
    this.referenceDataService,
    [
      {
        name: "Clone",
        onAction: ((d: SpaOverride) => this.onCloneAction(d)).bind(this),
      },
      {
        name: "Edit",
        onAction: ((d: SpaOverride) => this.onUpdateAction(d)).bind(this),
      },
      {
        name: "Delete",
        onAction: ((d: SpaOverride) =>
          this.delete.emit({
            id: d.service_point_id,
            effective_date: moment(d.effective_date).toDate(),
          })).bind(this),
      },
    ]
  );
  public columnDefs: ColDef[] = this.spahOverrideColumnDefinition.getColDefs();
  public filters = this.spahOverrideColumnDefinition.filters;
}
