import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { CellComponent } from "./cell.component";
import { EditableCellButtonComponent } from "./editable-cell-button/editable-cell-button.component";
import { SelectMenuListItemComponent } from "../filters/select-menu-list-item/select-menu-list-item.component";
import { SelectMenuListComponent } from "../filters/select-menu-list/select-menu-list.component";
import { TableCellPanelComponent } from "./table-cell-panel/table-cell-panel.component";
import { BoolDisplayPipe } from "src/app/shared/pipes/boolDisplay.pipe";

@Component({
  standalone: true,
  imports: [
    EditableCellButtonComponent,
    TableCellPanelComponent,
    SelectMenuListComponent,
    SelectMenuListItemComponent,
    BoolDisplayPipe
  ],
  styles: `
        :host {
            position: relative;
        }
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit) {
    <div app-editable-cell-button (select)="selectCellForEdit()">
      {{ currentValue | boolDisplay }}
    </div>

    <div
      #panel
      app-table-cell-panel
      [show]="editableRowService.state.openField === field"
    >
      <ul app-select-menu-list>
        <li
          app-select-menu-list-item
          [value]="true"
          [display]="true | boolDisplay"
          [selected]="true === currentValue"
          [multi]="false"
          (select)="setValue($event)"
        ></li>
        <li
          app-select-menu-list-item
          [value]="false"
          [display]="false | boolDisplay"
          [selected]="false === currentValue"
          [multi]="false"
          (select)="setValue($event)"
        ></li>
      </ul>
    </div>
    }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    {{ params.value | boolDisplay }}
    <!-- END CELL SPECIFIC -->
    }
  `,
})
export class FlagCellComponent<
  DATA extends Record<string, unknown>
> extends CellComponent<DATA, {}, keyof DATA, DATA[keyof DATA] & boolean> {
  @ViewChild("panel", { read: ElementRef })
  panelRef: ElementRef<HTMLDivElement>;
  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.field &&
      this.editableRowService.state.openField === this.field &&
      this.panelRef &&
      !this.panelRef.nativeElement.contains(event.target as Node)
    ) {
      this.unselectCellForEdit();
    }
  }
  
  setValue(val: boolean) {
    if (this.field) {
      this.editableRowService.updateValue(this.field as string, val as DATA[keyof DATA] & boolean);
    }
  }
}
