import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'inList',
    standalone: true,
    pure: true
})
export class InListPipe<T> implements PipeTransform {
    transform(value: T, list: T[], compFn: (v1: T, v2: T) => boolean): boolean {
        return list.find(i => compFn(i, value)) !== undefined
    }
}