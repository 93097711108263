import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  standalone: true,
  selector: "[app-editable-cell-button]",
  template: `
    <button class="edit_button" role="button" (click)="handleClick($event)">
      <ng-content></ng-content>&nbsp;
    </button>
  `,
  styles: `
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            padding: 0.5rem 0;
        }

        .edit_button {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid black;
            line-height: calc(100% + 0.5rem);
            font-weight: 500;
            padding-left: 0;
        }
    `,
})
export class EditableCellButtonComponent {
  @Output()
  select = new EventEmitter<void>();

  handleClick(evt: MouseEvent) {
    evt.stopImmediatePropagation();
    this.select.emit(undefined);
  }
}
