import {
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
} from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";
import { TableEditableRowService } from "../services/table-editable-row.service";

export type ParamsType<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  VALUE
> = ICellRendererParams<DATA & { edit_mode?: boolean }, VALUE> &
  PROPS;

@Component({
  standalone: true,
  template: ``,
  styles: ``,
})
export abstract class CellComponent<
  DATA extends Record<string, unknown>,
  PROPS extends Record<string, unknown>,
  FIELD extends keyof DATA,
  VALUE extends DATA[FIELD]
> implements ICellRendererAngularComp, OnDestroy
{
  params: ParamsType<DATA, PROPS, VALUE>;
  inEditMode: boolean;
  canEdit: boolean;
  field: FIELD | undefined;
  headerName: string;
  editableRowService = inject(TableEditableRowService);


  get currentValue() {
    if (this.field) {
      return this.editableRowService.state.data[this.field as string];
    }
    return undefined;
  }

  agInit(params: ParamsType<DATA, PROPS, VALUE>): void {
    this.refresh(params);
    this.init();
  }

  init() {}
  onOpen() {}
  onClose() {}

  refresh(params: ParamsType<DATA, PROPS, VALUE>) {
    this.destroy();
    this.params = params;
    this.field = this.params.colDef?.field as FIELD | undefined;
    this.headerName = this.params.colDef?.headerName ?? ''
    this.inEditMode = this.editableRowService.state.index === this.params.node.rowIndex
    this.canEdit = !!(
      this.field &&
      this.inEditMode
    );
    return true;
  }

  selectCellForEdit() {
    if(this.field) {
      this.editableRowService.openField(this.field as string)
    }
    this.onOpen();
    this.refresh(this.params);
  }

  unselectCellForEdit() {
    if(this.field){
      this.editableRowService.openField(this.field as string)
    }
    this.onClose();
    this.refresh(this.params);
  }

  ngOnDestroy(): void {
    this.destroy();
    this.onClose();
  }

  destroy(): void {}
}
