import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TableConfirmActionService } from "../services/table-confirm-action-service";
import { Subscription } from "rxjs";

@Component({
  standalone: true,
  selector: "app-table-confirm-action",
  template: `<ng-template #modal let-modal>
    <div class="modal-body">
      <p>{{ confirmActionService.message }}</p>
    </div>
    <div class="modal-footer">
      <button
        class="confirm-btn error"
        (click)="confirmActionService.onConfirm()"
      >
        {{ confirmActionService.confirmText }}
      </button>
      <button class="confirm-btn" (click)="confirmActionService.onCancel()">
        {{ confirmActionService.cancelText }}
      </button>
    </div>
  </ng-template>`,
  styles: `
    :host {
        display: none;
    }

    .confirm-btn{
        padding: 0.5rem 1rem;
        border: none;
        background: white;
        text-align: center;
        border: 1px solid black;
        border-radius: 0.25rem;
        font-size: 0.9rem;
        line-height: 1.25rem;

        &:focus {
        outline: none;
        }

        &.error {
        border-color: var(--error-red);
        color: var(--error-red)
        }
    }
    `,
})
export class TableConfirmActionComponent implements OnInit, OnDestroy {
  public confirmActionService = inject(TableConfirmActionService);
  public modalService = inject(NgbModal);

  private subscription = new Subscription();
  @ViewChild("modal")
  private modalRef: TemplateRef<any>;

  ngOnInit() {
    this.subscription.add(
      this.confirmActionService.open$.subscribe((open) => {
        if (open) {
          this.modalService.open(this.modalRef, {
            windowClass: "confirmation-modal",
            centered: true,
          });
        } else {
          this.modalService.dismissAll();
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
