import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    standalone: true,
    selector: '[app-select-menu-list-item]',
    host: {
        '[class.selected]': '!!selected'
    },
    template: `
        <button (click)="select.emit(value)">
            @if(multi) {
                <span class="display-multi" [class.selected]="!!selected">&nbsp;</span>
            }
            <span class="display-value">
                @if(label) {
                    <span class="display-label">{{label}}</span>
                }{{display}}
            </span>
        </button>
    `,
    styles: `
        :host{
            margin: 0;
            padding: 0;
            
            &.selected {
                background: gainsboro;
            }

            &:hover {
                background: lightgray;
            }
        }

        button {
            position: relative;
            display: flex;
            justify-content: start;
            align-items: start;
            gap: 0.75rem;
            width: 100%;
            padding: 0.75rem 0 0.75rem 1rem;
            margin: 0;
            background: none;
            border: none;
            text-align: start;
            font-size: 0.9rem;
            line-height: 1.25rem;
        }

        .display {
            &-value {
                position: relative;
                white-space: wrap;
                width: 100%;
            }

            &-label {
                position: absolute;
                font-size: 0.7rem;
                line-height: 0.8rem;
                opacity: 0.8;
                top: -0.5rem;
                width:  100%;
                text-overflow: ellipsis;
            }

            &-multi {
                position: relative;
                min-width: 1.25rem;
                width: 1.25rem;
                height: 1.25rem;
                border: 1px solid black;
                border-radius: 0.25rem;

                &.selected {
                    background: var(--active-blue);
                    &::before {
                        display: block;
                        position: absolute;
                        content: ' ';
                        width: 1rem;
                        height: 0.6rem;
                        transform: rotate(-50deg);
                        left: 0.08rem;
                        top: -0.1rem;
                        box-shadow: -3px 3px 1px white;
                    }
                }
            }
        }
    `
})
export class SelectMenuListItemComponent<T> {
    @Input({required: true})
    value: T;
    @Input({required: true})
    display: string;

    @Input()
    selected?: boolean;
    @Input()
    multi?: boolean;
    @Input()
    label?: string;

    @Output()
    select = new EventEmitter<T>()
}