import { Component, ElementRef, HostListener, inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Observable, Subscription } from "rxjs";
import { EntityRef } from "src/app/shared/models/referenceData";
import {
  PaginationParams,
  FilterParams,
  SortParams,
} from "src/app/shared/utilities/http-params";
import { FormsModule } from "@angular/forms";
import { SelectMenuListItemComponent } from "../filters/select-menu-list-item/select-menu-list-item.component";
import { SelectMenuListPagingComponent } from "../filters/select-menu-list-paging-item/select-menu-list-paging-item.component";
import { SelectMenuListErrorComponent } from "../filters/select-menu-list-error-item/select-menu-list-error-item.component";
import { SelectMenuListloadingComponent } from "../filters/select-menu-list-loading-item/select-menu-list-loading-item.component";
import { SelectMenuListComponent } from "../filters/select-menu-list/select-menu-list.component";
import { SelectMenuSearchComponent } from "../filters/select-menu-search/select-menu-search.component";
import { SelectMenuListNoItemsComponent } from "../filters/select-menu-list-no-items-item/select-menu-list-no-items-item.component";
import { TableHeaderFilterButton } from "./table-header-filter-button/table-header-filter-button.component";
import { TableHeaderSortButtonComponent } from "./table-header-sort-button/table-header-sort-button.component";
import { TableHeaderFilterPanelComponent } from "./table-header-filter-panel/table-header-filter-panel.component";
import { FILTER_LIST_STATE_FACTORY, FilterListState } from "src/app/shared/utilities/filter-list-state";
import { TableFilterService } from "../services/table-filter.service";
import { TableSortService, CurrentSort } from "../services/table-sort.service";
import { IHeaderParams } from "ag-grid-community";
import {
  FilterSearchMeta,
  FilterSearchState,
} from "src/app/shared/utilities/filter-search-state";
import { InListPipe } from "src/app/shared/pipes/inList.pipe";
import { SelectMenuListClearButtonItemComponent } from "../filters/select-menu-list-clear-button-item/select-menu-list-clear-button-item.component";

type EntityCodeHeaderComponentProps<T extends Record<string, any>> = {
  filterState?: FilterListState<EntityRef, T>;

  fetchEntityRefs: (
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) => Observable<EntityRef[]>;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableHeaderSortButtonComponent,
    TableHeaderFilterButton,
    TableHeaderFilterPanelComponent,
    SelectMenuSearchComponent,
    SelectMenuListComponent,
    SelectMenuListClearButtonItemComponent,
    SelectMenuListloadingComponent,
    SelectMenuListErrorComponent,
    SelectMenuListItemComponent,
    SelectMenuListNoItemsComponent,
    SelectMenuListPagingComponent,
    InListPipe,
  ],
  styles: `
        :host {
            position: relative;
            display: grid;
            grid-template-columns: 1fr minmax(0, 1.5rem);
            gap: 0.25rem;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            font-weight: 700;
            width: 100%;
        }
    `,
  template: `
    <button
      app-table-header-sort-button
      [label]="headerName"
      [currentSort]="currentSort"
      (click)="sort($event)"
    ></button>

    @if(filterable) {
    <button
      app-table-header-filter-button
      [filterCount]="selected.length"
      (click)="toggleFilter()"
    ></button>
    }

    <div app-table-header-filter-panel [show]="isFilterOpen">
      <!-- SHOW SEARCH -->
      <div
        app-select-menu-search
        [name]="field"
        [label]="headerName"
        [value]="searchMeta?.searchTerm ?? ''"
        (search)="this.searchState.search($event)"
      ></div>

      <ul app-select-menu-list (scroll)="onScroll($event)">
        <!-- SHOW SELECTED FILTER VALUES -->
        @if(selected.length) {
        <li
          app-select-menu-list-clear-button-item
          (click)="filterState.clear()"
        >
        </li>
        }
        @for (item of selected; track item.entity_code) {
        <li
          app-select-menu-list-item
          [value]="item"
          [display]="item.entity_name"
          [label]="item.entity_code"
          [selected]="item | inList : selected : compareEntityRefs"
          [multi]="true"
          (select)="setValue($event)"
        ></li>
        } @if(selected.length) {
        <li><hr /></li>
        }

        <!-- SHOW LOADING INDICATOR -->
        @if(searchMeta?.searching) {
        <li app-select-menu-list-loading-item></li>
        }

        <!-- SHOW ERROR -->
        @else if(searchMeta?.error ) {
        <li app-select-menu-list-error-item></li>
        }

        <!-- SHOW ENTITY REFS -->
        @else {
        <!-- SHOW UNSELECTED SEARCH RESULTS -->
        @for (result of searchResults; track result.entity_code;) {
        <li
          app-select-menu-list-item
          [value]="result"
          [display]="result.entity_name"
          [label]="result.entity_code"
          [selected]="result | inList : selected : compareEntityRefs"
          [multi]="true"
          (select)="setValue($event)"
        ></li>
        }

        <!-- SHOW NO RESULTS -->
        @if(!searchResults.length) {
        <li app-select-menu-list-no-items-item></li>
        }

        <!-- SHOW PAGING -->
        @if(searchMeta?.paging ) {
        <li app-select-menu-list-paging-item></li>
        } }
      </ul>
    </div>
  `,
})
export class EntityCodeHeaderComponent<DATA extends Record<string, unknown>> {
  private elementRef: ElementRef<HTMLDivElement> = inject(ElementRef);
  private sortService = inject(TableSortService);
  public filterService = inject(TableFilterService);
  public filterListStateFactory = inject(FILTER_LIST_STATE_FACTORY);

  private subscription = new Subscription();

  public params: IHeaderParams<DATA> & EntityCodeHeaderComponentProps<DATA>;
  public headerName: string = "";
  public field: string = "";
  public sortable: boolean = false;
  public filterable: boolean = false;
  public currentSort: CurrentSort | undefined = undefined;
  public isFilterOpen: boolean = false;
  public selected: EntityRef[] = [];
  public searchResults: EntityRef[] = [];
  public searchMeta?: FilterSearchMeta;
  public filterState: FilterListState<EntityRef>;
  public searchState: FilterSearchState<EntityRef>;
  public searchStateMeta: FilterSearchState<EntityRef>;

  agInit(params: IHeaderParams<DATA> & EntityCodeHeaderComponentProps<DATA>): void {
    this.refresh(params);
  }

  refresh(
    params: IHeaderParams<DATA> & EntityCodeHeaderComponentProps<DATA>
  ): boolean {
    this.params = params;
    this.headerName = params.column.getColDef().headerName ?? "";
    this.field = params.column.getColDef().field ?? "";
    this.sortable = params.column.isSortable();
    this.filterable = params.column.isFilterAllowed();
    return true;
  }

  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.isFilterOpen &&
      !this.elementRef.nativeElement.contains(event.target as Node)
    ) {
      this.filterService.toggle(this.field);
    }
  }

  ngOnInit(): void {
    this.filterState = this.params.filterState ??this.filterListStateFactory<EntityRef>(
      this.field,
      (val) => val.entity_code
    );

    this.searchState = new FilterSearchState<EntityRef>(
      ((searchTerm: string, pageNumber: number, pageSize: number) =>
        this.params.fetchEntityRefs(
          { pageNumber, pageSize },
          [{ name: "entity_name", values: [{ value: searchTerm }] }],
          [{ name: "entity_name" }]
        )).bind(this)
    );

    this.subscription.add(
      this.filterState.list$.subscribe((l) => {
        this.selected = l;
      })
    );
    this.subscription.add(
      this.filterState.params$.subscribe((p) => {
        this.filterService.update(p);
      })
    );
    this.subscription.add(
      this.filterService.isOpen(this.field).subscribe((isOpen) => {
        this.isFilterOpen = isOpen;
      })
    );
    this.subscription.add(
      this.sortService.getCurrentSort(this.field).subscribe((cs) => {
        this.currentSort = cs;
      })
    );
    this.subscription.add(
      this.searchState.results$.subscribe((r) => {
        this.searchResults = r;
      })
    );
    this.subscription.add(
      this.searchState.meta$.subscribe((m) => {
        this.searchMeta = m;
      })
    );
  }

  toggleFilter() {
    if (!this.isFilterOpen) {
      this.searchState.search(this.searchMeta?.searchTerm ?? "");
    }
    this.filterService.toggle(this.field);
  }

  compareEntityRefs(a: EntityRef, b: EntityRef) {
    return a.entity_code === b.entity_code;
  }

  has(val: EntityRef) {
    return (
      this.selected.find((s) => s.entity_code === val.entity_code) !== undefined
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  sort(evt: MouseEvent) {
    if (this.sortable) {
      this.sortService.next({
        name: this.field,
        multi: evt.ctrlKey,
      });
    }
  }

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    if (
      this.searchMeta?.hasMore &&
      reachedThreshold &&
      !this.searchMeta.paging &&
      !this.searchMeta.searching
    ) {
      this.searchState.page();
    }
  }

  setValue(val: EntityRef) {
    this.has(val)
      ? this.filterState.remove(val)
      : this.filterState.add(val, true);
  }
}
