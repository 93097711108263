import { CommonModule } from "@angular/common";
import { Component, ElementRef, inject, Input } from "@angular/core";
import { distinctUntilChanged, filter, interval, map, NEVER, ReplaySubject, switchMap } from "rxjs";

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: '[app-table-header-filter-panel]',
    template: `
        <div 
            class="panel"
            [style]="panelPos$ | async"
            [class.hidden]="!show">
            <ng-content></ng-content>
        </div>
    `,
    styles: `
    :host {
        position: absolute;
        bottom: -1rem;
        left: -1rem;
        width: 0;
        height: 0;
    }

    .panel {
        position: fixed;
        width: 12rem;
        background-color: white;
        border: 1px solid black;
        border-radius: 0;
        z-index: 100000;

        &::before {
            content: ' ';
            border: none;
            border-top: 1px solid black;
            border-right: 1px solid black;
            width: 0.5rem;
            height: 0.5rem;
            transform: rotate(-45deg);
            position: absolute;
            top: calc(-0.25rem - 1px);
            background: linear-gradient(
            45deg, 
            rgba(255,255,255,0) 0%, 
            rgba(255,255,255,0) calc(50% - 1px), 
            rgba(255,255,255,1) calc(50% - 1px), 
            rgba(255,255,255,1) 100%
            );
            left: 1rem;
        }

        &.hidden {
            display: none;
        }
    }
    `
})
export class TableHeaderFilterPanelComponent {
    elementRef = inject(ElementRef);

    private _open$ = new ReplaySubject(1);
    public panelPos$ = this._open$
        .asObservable()
        .pipe(
            distinctUntilChanged(),
            switchMap((open) => open ? interval(30): NEVER),
            map(() => (<HTMLDivElement>this.elementRef.nativeElement)),
            filter(Boolean),
            map((el) => el.getBoundingClientRect()),
            map((rect) => ({
                left: `${rect.left}px`,
                top: `${rect.top}px`,
                width: this.width ?? '14rem'
            }))
        )

    @Input() width: string;

    private _show: boolean = false;
    @Input({ required: true }) 
    public get show() {
        return this._show;
    };

    public set show(val: boolean) {
        this._open$.next(val);
        this._show = val;
    }
}