import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServicePointResponse } from "src/app/shared/models/servicePointResponse";
import {
  PaginationParams,
  FilterParams,
  SortParams,
  httpParams,
  withHttpParams,
  pagination,
  sort,
  filter,
} from "src/app/shared/utilities/http-params";
import { catchError, Observable, of } from "rxjs";
import { SpaOverrideResponse } from "src/app/shared/models/spaOverrideResponse";
import * as moment from "moment";
import { SpaOverride } from "src/app/shared/models/spaOverride";

export interface Filter {
  field: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class SpaOverrideService {
  constructor(private http: HttpClient) {}

  createOverride(data: Partial<SpaOverride>, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.post<ServicePointResponse>("spah_override/", {...data, dry_run: dryRun}, {
      headers: {
        NOLOADING: "true",
        EXPECT_FAILURE: 'true'
      },
    }).pipe(
      catchError((err): Observable<ServicePointResponse> => of({ 
        results: new Array(50).fill(0).map((_, i) => ({
          as_of_date: moment().format('YYY-MM-DD'),
          effective_date:  moment().format('YYY-MM-DD'),
          termination_date:  moment().format('YYY-MM-DD'),
          service_point_id: 'service_point_id_' + i,
          service_point_source_code: 'service_point_source_code_' + i,
          ldc_code: 'ldc_code_' + i,
          eldc_code: 'eldc_code_' + i,
          profile_class_code: 'profile_class_code_' + i,
          retailer_code: 'retailer_code_' + i,
          supply_class_code: 'supply_class_code_' + i,
          meter_type_code: 'meter_type_code_' + i,
          loss_class_code: 'loss_class_code_' + i,
          location_code: 'location_code_' + i,
          cpnode_code: 'cpnode_code_' + i,
          spa_override_flag: false,
          calculation_type_internal_code: 'calculation_type_internal_code_' + i,
          bmg_flag: false,
          tie_flag: false,
          weather_sensitive_flag: false,
          usage_ingestion_block_flag: false,
          net_meter_rider_flag: false,
          service_point_uid: 'service_point_uid_' + i,
          service_point_source_uid: 'service_point_source_uid_' + i,
          ldc_uid: 'ldc_uid_' + i,
          eldc_uid: 'eldc_uid_' + i,
          profile_class_uid: 'profile_class_uid_' + i,
          retailer_uid: 'retailer_uid_' + i,
          supply_class_uid: 'supply_class_uid_' + i,
          meter_type_uid: 'meter_type_uid_' + i,
          loss_class_uid: 'loss_class_uid_' + i,
          location_uid: 'location_uid_' + i,
          cpnode_uid: 'cpnode_uid_' + i,
          create_time: moment().toISOString(),
          created_by: 'created_by_' + i,
          last_update_time:  moment().toISOString(),
          last_updated_by: 'last_updated_by_' + i,
        })),
        total: 50
      }))
    );
  }

  updateOverride(id: string, effectiveDate: Date, data: Partial<SpaOverride>, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.post<ServicePointResponse>(`spah_override/${id}/${moment(effectiveDate).format('YYYY-MM-DD')}`, {...data, dry_run: dryRun}, {
      headers: {
        NOLOADING: "true",
        EXPECT_FAILURE: 'true'
      },
    }).pipe(
      catchError((err): Observable<ServicePointResponse> => of({ 
        results: new Array(50).fill(0).map((_, i) => ({
          as_of_date: moment().format('YYY-MM-DD'),
          effective_date:  moment().format('YYY-MM-DD'),
          termination_date:  moment().format('YYY-MM-DD'),
          service_point_id: 'service_point_id_' + i,
          service_point_source_code: 'service_point_source_code_' + i,
          ldc_code: 'ldc_code_' + i,
          eldc_code: 'eldc_code_' + i,
          profile_class_code: 'profile_class_code_' + i,
          retailer_code: 'retailer_code_' + i,
          supply_class_code: 'supply_class_code_' + i,
          meter_type_code: 'meter_type_code_' + i,
          loss_class_code: 'loss_class_code_' + i,
          location_code: 'location_code_' + i,
          cpnode_code: 'cpnode_code_' + i,
          spa_override_flag: false,
          calculation_type_internal_code: 'calculation_type_internal_code_' + i,
          bmg_flag: false,
          tie_flag: false,
          weather_sensitive_flag: false,
          usage_ingestion_block_flag: false,
          net_meter_rider_flag: false,
          service_point_uid: 'service_point_uid_' + i,
          service_point_source_uid: 'service_point_source_uid_' + i,
          ldc_uid: 'ldc_uid_' + i,
          eldc_uid: 'eldc_uid_' + i,
          profile_class_uid: 'profile_class_uid_' + i,
          retailer_uid: 'retailer_uid_' + i,
          supply_class_uid: 'supply_class_uid_' + i,
          meter_type_uid: 'meter_type_uid_' + i,
          loss_class_uid: 'loss_class_uid_' + i,
          location_uid: 'location_uid_' + i,
          cpnode_uid: 'cpnode_uid_' + i,
          create_time: moment().toISOString(),
          created_by: 'created_by_' + i,
          last_update_time:  moment().toISOString(),
          last_updated_by: 'last_updated_by_' + i,
        })),
        total: 50
      }))
    );
  }

  deleteOverride(id: string, effectiveDate: Date, dryRun: boolean): Observable<ServicePointResponse> {
    return this.http.delete<ServicePointResponse>(`spah_override/${id}/${moment(effectiveDate).format('YYYY-MM-DD')}`, {
      headers: {
        NOLOADING: "true",
        EXPECT_FAILURE: 'true'
      },
      params: {
        dry_run: dryRun
      }
    }).pipe(
      catchError((err): Observable<ServicePointResponse> => of({ 
        results: new Array(50).fill(0).map((_, i) => ({
          as_of_date: moment().format('YYY-MM-DD'),
          effective_date:  moment().format('YYY-MM-DD'),
          termination_date:  moment().format('YYY-MM-DD'),
          service_point_id: 'service_point_id_' + i,
          service_point_source_code: 'service_point_source_code_' + i,
          ldc_code: 'ldc_code_' + i,
          eldc_code: 'eldc_code_' + i,
          profile_class_code: 'profile_class_code_' + i,
          retailer_code: 'retailer_code_' + i,
          supply_class_code: 'supply_class_code_' + i,
          meter_type_code: 'meter_type_code_' + i,
          loss_class_code: 'loss_class_code_' + i,
          location_code: 'location_code_' + i,
          cpnode_code: 'cpnode_code_' + i,
          spa_override_flag: false,
          calculation_type_internal_code: 'calculation_type_internal_code_' + i,
          bmg_flag: false,
          tie_flag: false,
          weather_sensitive_flag: false,
          usage_ingestion_block_flag: false,
          net_meter_rider_flag: false,
          service_point_uid: 'service_point_uid_' + i,
          service_point_source_uid: 'service_point_source_uid_' + i,
          ldc_uid: 'ldc_uid_' + i,
          eldc_uid: 'eldc_uid_' + i,
          profile_class_uid: 'profile_class_uid_' + i,
          retailer_uid: 'retailer_uid_' + i,
          supply_class_uid: 'supply_class_uid_' + i,
          meter_type_uid: 'meter_type_uid_' + i,
          loss_class_uid: 'loss_class_uid_' + i,
          location_uid: 'location_uid_' + i,
          cpnode_uid: 'cpnode_uid_' + i,
          create_time: moment().toISOString(),
          created_by: 'created_by_' + i,
          last_update_time:  moment().toISOString(),
          last_updated_by: 'last_updated_by_' + i,
        })),
        total: 50
      }))
    );
  }

  getOverrides(
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) {
    const params = httpParams(
      withHttpParams(pagination, page),
      withHttpParams(filter, filters),
      withHttpParams(sort, order)
    );
    return this.http.get<SpaOverrideResponse>("spah_override/", {
      params,
      headers: {
        NOLOADING: "true",
      },
    });
  }
}
