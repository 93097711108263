import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { CellComponent } from "./cell.component";
import { Observable, Subscription } from "rxjs";
import { EntityRef } from "src/app/shared/models/referenceData";
import {
  PaginationParams,
  FilterParams,
  SortParams,
} from "src/app/shared/utilities/http-params";
import { StringPipe } from "src/app/shared/pipes/string.pipe";
import { SelectMenuListItemComponent } from "../filters/select-menu-list-item/select-menu-list-item.component";
import { SelectMenuListComponent } from "../filters/select-menu-list/select-menu-list.component";
import { EditableCellButtonComponent } from "./editable-cell-button/editable-cell-button.component";
import { TableCellPanelComponent } from "./table-cell-panel/table-cell-panel.component";
import { FilterListState } from "src/app/shared/utilities/filter-list-state";
import {
  FilterSearchMeta,
  FilterSearchState,
} from "src/app/shared/utilities/filter-search-state";
import { SelectMenuListErrorComponent } from "../filters/select-menu-list-error-item/select-menu-list-error-item.component";
import { SelectMenuListloadingComponent } from "../filters/select-menu-list-loading-item/select-menu-list-loading-item.component";
import { SelectMenuListNoItemsComponent } from "../filters/select-menu-list-no-items-item/select-menu-list-no-items-item.component";
import { SelectMenuListPagingComponent } from "../filters/select-menu-list-paging-item/select-menu-list-paging-item.component";
import { SelectMenuSearchComponent } from "../filters/select-menu-search/select-menu-search.component";

type BasicEntityRefCellComponentProps = {
  fetchEntityRefs: (
    page?: PaginationParams,
    filters?: FilterParams[],
    order?: SortParams[]
  ) => Observable<EntityRef[]>;
};

@Component({
  standalone: true,
  imports: [
    EditableCellButtonComponent,
    TableCellPanelComponent,
    SelectMenuListComponent,
    SelectMenuSearchComponent,
    SelectMenuListItemComponent,
    SelectMenuListloadingComponent,
    SelectMenuListErrorComponent,
    SelectMenuListNoItemsComponent,
    SelectMenuListPagingComponent,
    StringPipe,
  ],
  styles: `
      :host {
          position: relative;
      }
    `,
  template: `
    <!-- SHOW EDITING MODE -->
    @if (canEdit) {
    <div app-editable-cell-button (select)="selectCellForEdit()">
      {{ currentValue }}
    </div>

    <div
      #panel
      app-table-cell-panel
      [show]="editableRowService.state.openField === field"
    >
      <!-- SHOW SEARCH -->
      <div
        app-select-menu-search
        [name]="field | string"
        [label]="headerName"
        [value]="searchMeta?.searchTerm ?? ''"
        (search)="this.searchState.search($event)"
      ></div>

      <ul app-select-menu-list (scroll)="onScroll($event)">
        <!-- SHOW LOADING INDICATOR -->
        @if(searchMeta?.searching) {
        <li app-select-menu-list-loading-item></li>
        }

        <!-- SHOW ERROR -->
        @else if(searchMeta?.error ) {
        <li app-select-menu-list-error-item></li>
        }

        <!-- SHOW ENTITY REFS -->
        @else {
        <!-- SHOW SEARCH RESULTS -->
        @for (result of searchResults; track result.entity_code;) {
        <li
          app-select-menu-list-item
          [value]="result"
          [display]="result.entity_name"
          [label]="result.entity_code"
          [selected]="result.entity_code === currentValue"
          [multi]="false"
          (select)="setValue($event)"
        ></li>
        }

        <!-- SHOW NO RESULTS -->
        @if(!searchResults.length) {
        <li app-select-menu-list-no-items-item></li>
        }

        <!-- SHOW PAGING -->
        @if(searchMeta?.paging ) {
        <li app-select-menu-list-paging-item></li>
        } }
      </ul>
    </div>
    }

    <!-- SHOW VALUE IN TABLE -->
    @else {
    <!-- START CELL SPECIFIC -->
    {{ params.value }}
    <!-- END CELL SPECIFIC -->
    }
  `,
})
export class BasicEntityRefCellComponent<DATA extends Record<string, unknown>>
  extends CellComponent<
    DATA,
    BasicEntityRefCellComponentProps,
    keyof DATA,
    DATA[keyof DATA] & string
  >
  implements OnInit
{
  private subscription = new Subscription();

  public searchResults: EntityRef[] = [];
  public searchMeta?: FilterSearchMeta;
  public filterState: FilterListState<EntityRef>;
  public searchState: FilterSearchState<EntityRef>;
  public searchStateMeta: FilterSearchState<EntityRef>;

  @ViewChild("panel", { read: ElementRef })
  panelRef: ElementRef<HTMLDivElement>;
  @HostListener("document:click", ["$event"])
  handleOutsideClick(event: Event) {
    if (
      this.field &&
      this.editableRowService.state.openField === this.field &&
      this.panelRef &&
      !this.panelRef.nativeElement.contains(event.target as Node)
    ) {
      this.unselectCellForEdit();
    }
  }

  ngOnInit(): void {
    this.searchState = new FilterSearchState<EntityRef>(
      ((searchTerm: string, pageNumber: number, pageSize: number) =>
        this.params.fetchEntityRefs(
          { pageNumber, pageSize },
          [{ name: "entity_name", values: [{ value: searchTerm }] }],
          [{ name: "entity_name" }]
        )).bind(this)
    );

    this.subscription.add(
      this.searchState.results$.subscribe((r) => {
        this.searchResults = r;
      })
    );
    this.subscription.add(
      this.searchState.meta$.subscribe((m) => {
        this.searchMeta = m;
      })
    );
  }

  override onOpen(): void {
    this.searchState.search("");
  }

  onScroll(evt: Event) {
    const { scrollHeight, scrollTop, clientHeight } = evt.target as any;
    const threshold = 15;
    const reachedThreshold =
      Math.abs(scrollHeight - clientHeight - scrollTop) < threshold;
    if (
      this.searchMeta?.hasMore &&
      reachedThreshold &&
      !this.searchMeta.paging &&
      !this.searchMeta.searching
    ) {
      this.searchState.page();
    }
  }

  setValue(item: EntityRef) {
    if (this.field) {
      this.editableRowService.updateValue(
        this.field as string,
        item.entity_code
      );
    }
  }
}
