import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    standalone: true,
    pure: true,
    name: 'boolDisplay'
})
export class BoolDisplayPipe implements PipeTransform {
    transform(value?: boolean | null | undefined) {
        return typeof  value === 'boolean'  
            ? value 
                ? 'Yes' 
                : 'No'
            : '';
    }

}