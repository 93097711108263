import { Component } from "@angular/core";


@Component({
    standalone: true,
    selector: '[app-select-menu-list-error-item]',
    template: `
    <p>An error occurred while fetching data.</p>
    <p>Please try again.</p>
    `,
    styles: `
    :host {
      margin: 0;
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 0.25rem;
      color: var(--error-red);
      text-align: center;
      text-wrap: auto;
      white-space: normal;
      line-height: 1rem;
      font-weight: normal;
    }
    `
})
export class SelectMenuListErrorComponent{
}