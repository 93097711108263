import { Component, inject } from "@angular/core";
import { map, Observable, Subject } from "rxjs";
import { TableFilterService } from "../table/services/table-filter.service";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { QuickDatePickerComponent } from "../quick-date-picker/quick-date-picker.component";
import { PageContentComponent } from "../page-content/page-content.component";
import { ServicePointAttributesTableComponent } from "../table/service-point-attributes-table.component";
import { AsyncPipe } from "@angular/common";
import * as moment from "moment";

@Component({
  standalone: true,
  selector: "app-service-points",
  imports: [
    PageHeaderComponent,
    QuickDatePickerComponent,
    PageContentComponent,
    ServicePointAttributesTableComponent,
    AsyncPipe,
  ],
  providers: [TableFilterService],
  template: `
    <app-page-header title="Service Point Attributes">
      <app-quick-datepicker
        label="Operating Date"
        [value]="operatingDate$ | async"
        (valueChange)="onQuickDateSelect($event)"
      />
    </app-page-header>
    <app-page-content>
      <app-service-point-attributes-table [quickDates$]="quickDates$" />
    </app-page-content>
  `,
  styles: ``,
})
export class ServicePointsComponent {
  filterService = inject(TableFilterService);
  operatingDate$: Observable<string | Date | null | undefined> = this.filterService.state$
    .pipe(
      map(state => {
        const effectiveDate = state.find(fp => fp.name === 'effective_date');
        const terminationDate = state.find(fp => fp.name === 'termination_date');
        if(
          effectiveDate && effectiveDate.values.length && effectiveDate.values[0].operation === '<=' &&
          terminationDate && terminationDate.values.length && terminationDate.values[0].operation === '>' &&
          moment(effectiveDate.values[0].value).isSame(terminationDate.values[0].value, 'day')
        ) {
          return effectiveDate.values[0].value
        }
        else {
          return undefined
        }
      })
    );

  quickDates$ = new Subject<Date>();

  onQuickDateSelect(val: Date) {
    this.quickDates$.next(val);
  }
}
